import React, { useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import logo from '../assets/images/logo.svg';
import bell from '../assets/images/bell-icon.svg';
import refresh from '../assets/images/refresh.svg';
import BankService from '../services/bank/bank-service';
import { UserContext } from '../context/user/user-context';

const header = (props) => {
    const bankService = new BankService();
    const contextData = useContext(UserContext);
    const [allBanks, setAllBanks] = contextData.banksData;
    const [allAccounts, setAllAccounts] = contextData.accountsData;
    const notificationHandler = () => {
        props.history.push('/notifications');
    };

    const refreshHandler = () => {
        bankService
            .syncBank()
            .then((res) => {
                if (res !== null) {
                }
            })
            .catch(() => {});
        bankService
            .getUserBanks()
            .then((res) => {
                if (res !== null) {
                    setAllBanks(res.Data);
                }
            })
            .catch((err) => {});
        bankService
            .getAllBanks()
            .then((res) => {
                if (res !== null) {
                    setAllAccounts(res.Data);
                }
            })
            .catch((err) => {});
    };

    const moveToPage = (type) => {
        props.history.push(type);
    };

    const showMenu = () => {
        document.body.classList.add('menu-open');
    };

    return (
        // Start Header
        <header className="page-header">
            <div className="row justify-content-between">
                <div className="col-auto">
                    <div className="logo-link-outer">
                        {/* Start Logo */}
                        <Link to="/" title="Infin8" className="logo-link">
                            <img src={logo} alt="Infin8" />
                        </Link>
                        {/* End Logo */}
                    </div>
                </div>

                <div className="col-auto">
                    {props.location.pathname == '/history' ||
                    props.location.pathname == '/expenses' ||
                    props.location.pathname == '/' ? (
                        <Button variant="link" onClick={refreshHandler} className="refresh-btn" title="Refresh">
                            <img src={refresh} alt="bell" />
                        </Button>
                    ) : null}
                    {/* Start Notification */}
                    <Button
                        variant="link"
                        onClick={notificationHandler}
                        className="notification-btn"
                        title="Notifications"
                    >
                        <i className="d-none"></i>
                        <img src={bell} alt="bell" />
                    </Button>
                    {/* End Notification */}

                    {/* Start Notification */}
                    <Button variant="link" onClick={showMenu} className="nav-icon d-lg-none" title="Navigation">
                        <span></span>
                        <span></span>
                        <span></span>
                    </Button>
                    {/* End Notification */}
                </div>
            </div>
        </header>
        // End Header
    );
};

export default withRouter(header);
