import React from 'react';
import { Form } from 'react-bootstrap';

const ReactstrapSwitchInput = ({ field: { ...fields }, ...props }) => {
    const { id, label, ...rest } = props;
    return (
        <Form.Group controlId={id}>
            <Form.Check type="switch" id={id} label={label} className="mt-3 mb-4" {...fields} {...rest} />
        </Form.Group>
    );
};
export default ReactstrapSwitchInput;
