import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLocale from './en';
import elLocale from './el';
import ptLocale from './pt';
import esLocale from './es';
import { Common } from '../utils/constants';
import { saveStorage, loadStorage } from '../utils/localStroage';

if (!loadStorage(Common.Keys.UserLanguage)) saveStorage(Common.Keys.UserLanguage, Common.FallbackLanguage);

const selectedLanguage = loadStorage(Common.Keys.UserLanguage);

const resources = {
    en: {
        //english
        translation: {
            ...enLocale,
        },
    },
    el: {
        //Greek
        translation: {
            ...elLocale,
        },
    },
    pt: {
        //Portuguese
        translation: {
            ...ptLocale,
        },
    },
    es: {
        //Spanish
        translation: {
            ...esLocale,
        },
    },
};

i18n.use(initReactI18next).init({
    lng: selectedLanguage,
    fallbackLng: Common.FallbackLanguage,
    debug: false,
    resources,
    interpolation: {
        escapeValue: false,
        formatSeparator: '.',
    },
});

export const changeLanguageHandler = (language: string) => {
    i18n.changeLanguage(language);
    saveStorage(Common.Keys.UserLanguage, language);
};

export default i18n;
