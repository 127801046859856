import firebase from 'firebase';
const firebaseConfig = {
    apiKey: 'AIzaSyAfZQRPo62EPCEryucy2POIHG6A5D3P084',
    authDomain: 'infin8.firebaseapp.com',
    databaseURL: 'https://infin8-default-rtdb.firebaseio.com',
    projectId: 'infin8',
    storageBucket: 'infin8.appspot.com',
    messagingSenderId: '628191052185',
    appId: '1:628191052185:web:643648316ee6d9a6bc9308',
    measurementId: 'G-6LNJFE1JB7',
};
firebase.initializeApp(firebaseConfig);
export default firebase;
