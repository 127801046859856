import React from 'react';
import Select from 'react-select';

const ReactSelectBox = (props) => {
    const { label, name, value, options, isMulti, ...rest } = props;
    return (
        <React.Fragment>
            {label && <label>{label}</label>}
            <Select
                name={name}
                classNamePrefix="custom-dropdown"
                value={value}
                options={options}
                isMulti={isMulti}
                isInvalid={props.isInvalid}
                onChange={(value) => props.onChange(value)}
                onBlur={() => props.onBlur && props.onBlur()}
                placeholder={props.placeholder}
                menuPlacement="auto"
                isClearable={true}
                {...rest}
            />
            {props.touched && props.errors && props.errors !== 'required' && (
                <div className="pb-0 invalid-feedback d-block invalid-feedback">{props.touched && props.errors} </div>
            )}
        </React.Fragment>
    );
};

export default ReactSelectBox;
