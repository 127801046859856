import React, { useEffect, useContext, useState } from 'react';
import './App.css';
import Routes from './routes/Routes';
import { UserContext } from './context/user/user-context';
import LoginService from './services/login/login-service';
import { addWhiteBg, removeWhiteBg } from './utils/helpers';
import Spinner from './layout/Spinner';
import BankService from './services/bank/bank-service';
import { loadStorage, removeStorage } from './utils/localStroage';
import { Common } from './utils/constants';

let inProgress = false;
let inProgressAccount = false;

const App: React.FunctionComponent<any> = (props: any) => {
    const [loadRoutes, setLoadRoutes] = useState(null);
    const loginService = new LoginService();
    const bankService = new BankService();
    const contextData = useContext(UserContext);
    const [isAuth, setIsAuth] = contextData.authData;
    const [userData, setUserData] = contextData.userData;
    const [allBanks, setAllBanks] = contextData.banksData;
    const [allAccounts, setAllAccounts] = contextData.accountsData;

    if (isAuth == true) removeWhiteBg();
    else addWhiteBg();

    useEffect(() => {
        loginService
            .setInitToken()
            .then(() => {
                loginService
                    .tryAutoLogin(true)
                    .then((res: any) => {
                        if (res.isLoggedIn === true) {
                            setIsAuth(true);
                            setUserData(res.userData);
                            loginService.setUserLanguage();
                        }

                        setLoadRoutes(true);
                    })
                    .catch((err) => setLoadRoutes(true));
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        if (isAuth && allBanks.length === 0 && !inProgress) {
            inProgress = true;
            bankService
                .getUserBanks()
                .then((res) => {
                    if (res !== null) {
                        setAllBanks(res.Data);
                    }
                    inProgress = false;
                })
                .catch((err) => {
                    inProgress = false;
                });
        }

        if (
            isAuth &&
            (loadStorage(Common.Keys.BanksUpdated) !== undefined || (allAccounts === null && !inProgressAccount))
        ) {
            removeStorage(Common.Keys.BanksUpdated);
            inProgressAccount = true;
            bankService
                .getAllBanks()
                .then((res) => {
                    if (res !== null) {
                        setAllAccounts(res.Data);
                    }
                    inProgressAccount = false;
                })
                .catch((err) => {
                    inProgressAccount = false;
                });
        }
    });

    return (
        <>
            {loadRoutes == true ? <Routes /> : null}
            <Spinner />
        </>
    );
};

export default App;
