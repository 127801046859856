import React from 'react';
import Header from './Header';
import SideBar from './SideBar';

class MainLayout extends React.Component {
    render() {
        const { children } = this.props;

        return (
            <>
                <Header />
                <div className="d-flex">
                    <SideBar />
                    <section className="main-content">{children}</section>
                </div>
            </>
        );
    }
}

export default MainLayout;
