import axios from 'axios';
import { loadStorage, removeStorage } from '../utils/localStroage';
import { AuthKeys } from '../utils/constants';
import { isBlank } from '../utils/helpers';
import LoginService from '../services/login/login-service';
import { Common } from '../utils/constants';

const InitService = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER,
});

InitService.interceptors.request.use(
    (req) => {
        const initToken = loadStorage(AuthKeys.InitToken);

        if (!isBlank(initToken)) req.headers['Authorization'] = 'Bearer ' + initToken;

        // let strLanguage = '';
        // if (loadStorage(AuthKeys.AuthToken) !== undefined)
        //     strLanguage = loadStorage(Common.Keys.UserLanguage) || Common.FallbackLanguage;
        // else strLanguage = Common.FallbackLanguage;

        let strLanguage = '';
        if (loadStorage(Common.Keys.UserLanguage) != null) strLanguage = loadStorage(Common.Keys.UserLanguage);
        else strLanguage = Common.FallbackLanguage;
        req.headers['Accept-Language'] = Common.AcceptLanguageCodes[strLanguage];
        req.headers['Content-Type'] = 'application/json';
        document.getElementById('infin8-spinner').classList.remove('page-loader-disable');
        return req;
    },
    (error) => {
        document.getElementById('infin8-spinner').classList.remove('page-loader-disable');
        return Promise.reject(error);
    },
);

InitService.interceptors.response.use(
    (res) => {
        document.getElementById('infin8-spinner').classList.add('page-loader-disable');
        return res;
    },
    (error) => {
        document.getElementById('infin8-spinner').classList.add('page-loader-disable');
        if (
            error.response &&
            [400, 401].indexOf(error.response.status) > -1 &&
            error.response.data &&
            error.response.data.length > 0 &&
            error.response.data[0].hasOwnProperty('code') &&
            error.response.data[0].hasOwnProperty('message') &&
            (error.response.data[0].code === Common.InvalidTokenCode ||
                error.response.data[0].code === Common.InvalidRefreshTokenCode) &&
            (error.response.data[0].message === 'invalidRefreshToken' ||
                error.response.data[0].message === 'Invalid access token')
        ) {
            removeStorage(AuthKeys.InitToken);
            removeStorage(AuthKeys.InitRefreshToken);
            removeStorage(AuthKeys.InitRefreshTokenExpireAt);

            const loginService = new LoginService();
            if (error.config.url === 'token/access/new' || error.config.url === 'session/anon/init') {
                loginService.setInitToken();
                return Promise.reject(error);
            }

            return loginService.setInitToken().then(() => {
                const initToken = loadStorage(AuthKeys.InitToken);

                error.config.headers['Authorization'] = 'Bearer ' + initToken;
                error.config.baseURL = undefined;
                return InitService.request(error.config);
            });
        }
        return Promise.reject(error);
    },
);

export default InitService;
