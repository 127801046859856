//language: English
export default {
    LoginTitle: 'All you will ever need for your banking',
    language: 'en',
    login: {
        loginLanguage: 'language',
        welcomeMsg: 'Welcome to Infin8',
        title: 'Login',
        email: 'Email or Username',
        password: 'Password',
        signupMsg: 'First time here?',
        signup: 'Signup',
        forgotPassword: 'Forgot password?',
    },
    signup: {
        email: 'Email',
        username: 'Username',
        fullName: 'Full name',
        password: 'Password',
        phoneNumber: 'Mobile number including country code with + sign',
        confirmpassword: 'Confirm Password',
        register: 'Register',
        emailOtp: 'Email OTP',
        phoneOtp: 'Phone OTP',
        backto: 'Back To',
        registrationSuccessTitle: 'You successfully registered.',
    },
    signupstep2: {
        step2: 'Step 2',
        mobileInstruction:
            'Enter Your Mobile Number with Country code (e.g +306944646447) to Receive A Verification Code',
        next: 'Next',
    },
    notification: {
        notificationTitle: 'Notifications',
    },
    error: {
        validateDifferentAccount: 'Please select different account',
        validatedIban: 'Please insert valid IBAN number',
        Required: 'Required',
        invalidEmail: 'Invalid Email',
        invalidPhone: 'Incorrect mobile number format',
        emailExist: 'User with this email already exists.',
        passwordsDoesntMatch: 'Check passwords',
        transactionNoData: 'No transactions found',
        newPassError: 'Check password',
        newPasswordConfirmInvalid: 'Check password confirmation',
        passwordStrongRules:
            'Password length must be between 6 to 16 chars and include lower and upper case letters, a number and one of this special chars: !@#$%&-_)*(',
    },
    individual: 'Individual',
    business: 'Business',
    accept: 'Accept',
    show: 'Show',
    hide: 'Hide',
    save: 'Save',
    update: 'Update',
    select: 'Select',
    cancel: 'Cancel',
    back: 'Back',
    DashboardTitle: 'Dashboard',
    DashboardNoAccounts: 'You have no accounts. Please connect your Bank and collect statistic.',
    Expenses: 'Analytics',
    History: 'History',
    Bank: 'Banks',
    AddBank: 'Add Bank',
    MyProfile: 'My Profile',
    Settings: 'Settings',
    TermsAndCond: 'Terms and conditions',
    Logout: 'Logout',
    TotalAmount: 'Total Amount',
    VatNumber: 'VAT Number',
    ConnectedBanks: 'Connected Banks',
    bankPayments: 'Payments',
    paymentMethods: 'Cards',
    subscriptionTitle: 'Subscription',
    News: 'News',
    Dashboard: {
        Tabs: {
            iHave: 'I Have',
            iOwe: 'I Owe',
            iCanSpend: 'I Can Spend',
        },
        balanceAcrossBanks: 'Balance across your banks',
        iHave_transactionalAccountsFilterTitle: 'Transactional Accounts',
        iHave_savingsAccountFilterTitle: 'Savings Accounts',
        iHave_termDepositFilterTitle: 'Term Deposit',
        iOwe_creditcardsFilterTitle: 'Credit cards',
        iOwe_overdraftsFilterTitle: 'Overdrafts',
        iCanSpend_noFeeSpendingFilterTitle: 'No fee spending',
        iCanSpend_feeSpendingFilterTitle: 'Fee spending',
        iCanSpend_transferToSpendFilterTitle: 'Transfer to spend',
        iHave_headerMessage: 'Account balances',
        iOwe_headerMessage: 'Amounts Due',
        iCanSpend_headerMessage: 'Available to spend',
    },
    Banks: {
        addBank: {
            reconnectWithPayments: 'Reconnect with payments',
            connectAllowPayment: 'Allow payments',
            searchPlaceholder: 'Search',
            title: 'Infin8 - Banks',
            pageTitle: 'Banks',
            button: {
                back: 'Back',
                connected: 'Connect',
                disconnected: 'Disconnect',
                reconnected: 'Reconnect',
            },
            text: {
                connectNote:
                    ' Important Note: Your bank will ask permission to connect to Afterbanks LTD which is our provider for bank connectivity. Please accept it.',
                bankName: 'Bank Name',
                bankswift: 'Bank Swift',
                countTransaction: 'Count Transaction',
                countAccount: 'Count Account',
                paymentSupport: 'Payments',
                bankLastSyncAt: 'Last Data Synchronization',
            },
            status: {
                connected: 'Connected',
                disconnected: 'Disconnected',
                pending: 'Pending',
                reconnect: 'Reconnect',
            },
        },
    },
    Dates: {
        today: 'Today',
        yesterday: 'Yesterday',
    },
    Transaction: {
        title: 'Infin8 - Transactions',
        last_transactions: 'Last Transactions',
        transaction_category: 'Transaction Category',
        description: 'Description',
        receipt: 'Receipt',
        selectTransactionCategory: 'Select transaction category',
        categoryChangeSuccess: 'Category successfully changed',
        transferSaveDocument: 'Document',
        transferSaveImage: 'Image',
        transferSaveReceiptAs: 'Save or send the receipt as a document or image.',
    },
    TransactionHistory: {
        title: 'Infin8 - Transactions History',
        pageTitle: 'Transactions History',
        button: {
            filter: 'Filter',
            clear: 'Clear',
            exportToExcel: 'Export To Excel',
        },
        label: {
            dateRange: 'From/To date',
            from: 'From',
            to: 'To',
            category: 'All Categories',
            bank: 'All Banks',
            cardOrAccount: 'Card or Account',
            income: 'Income',
            expense: 'Expense',
        },
    },
    Setting: {
        appSettingsLang: 'Application language',
        menuChangePass: 'Change password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        newPasswordConfirm: 'Confirm new password',
        passwordChangeSuccess: 'Your password successfully changed',
    },
    Profile: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'E-mail',
        dateOfBirth: 'Date of birth',
        phone: 'Phone',
        sex: 'Sex',
        postCode: 'Post Code',
        address: 'Address',
        company: 'Company',
        sexMale: 'Male',
        sexFemale: 'Female',
        profileSaved: 'Profile saved',
    },
    ExpensesPage: {
        title: 'Infin8 - Expenses',
        pageTitle: 'Analytics',
        button: {
            filter: 'Filter',
            clear: 'Clear',
            Exportpdf: 'Export to pdf',
        },
        label: {
            dateRange: 'From/To date',
            from: 'From',
            to: 'To',
            category: 'All Categories',
            bank: 'All Banks',
            income: 'Income',
            expense: 'Expenses',
            all: 'All',
            totalAmount: 'Total Amount',
            cashFlow: 'Cash flow',
        },
    },
    forgotPassword: {
        title: 'Forgot Password',
        pageTitle: 'Forgot Password',
        button: {
            continue: 'Continue',
            save: 'Save',
        },
        label: {
            email: 'Email',
            password: 'Enter new password',
            confirmpassword: 'Confirm new password',
            forgetPassword: 'Forgot Password',
            otp: 'OTP',
            provideOTP: 'Provide OTP',
            createNewPassword: 'Create new password',
            otpExpires: 'OTP expires in',
        },
    },
    alertMsg: {
        userPhoneExist: 'user phone is Exist',
        userExist: 'user email is Exist',
        wentWrong: 'Something went wrong. Please try again.',
        wrongOTP: 'Wrong OTP!',
        otpExpired: 'OTP expired. Please try again.',
        passwordChangeSuccess: 'Password changed successfully.',
        userNotFound: 'User not found.',
    },
    receipt: {
        description: 'Description',
        date: 'Date',
        amount: 'Amount',
        category: 'Category',
        bank: 'Bank',
        transDetails: 'Transaction Details',
    },
    accountTypes: {
        checking: 'Checking account',
        debit: 'Debit account',
        card: 'Card account',
        investment: 'Investment account',
        savings: 'Savings account',
    },
    transfer: {
        accountLabelSelect: 'Select account',
        currentBalance: 'Current balance',
        transferScreen: 'Transfer',
        sourceFrom: 'From',
        sourceTo: 'To',
        amount: 'Amount',
        transferDescription: 'Description',
        continue: 'Continue',
        acceptPaymentChargesViaStripe: 'I approve transactional charges via Stripe',
        transferIBANPlaceholder: 'IBAN',
        transferRecipientPlaceholder: 'Recipient name',
        transferConfirmationBottomText: 'Confirm',
        paymentPrecheckInfo_PAYMENT_METHOD1: 'Your card will be charged a transaction fee of ',
        paymentPrecheckInfo_PAYMENT_METHOD2: 'from Infin8. Bank charges may apply.',
        transactionRemaining: 'Transaction Remaining',
        anyIBAN: 'Any IBAN',
        ownAccounts: 'Own Accounts',
        iAgree: 'I agree to',
        paymentIntiation: 'Payment Initiation',
        termsConditions: 'Terms & Conditions',
        ibanNumber: 'IBAN',
        note: 'Note',
        transferText:
            'Please note that it may take up to 3 days for the transaction to be validated and executed by your Bank',
    },
    card: {
        paymentMethodsCreateTitle: 'Add new card',
        default: 'Default',
        paymentMethodsDeleteButton: 'Delete card',
        paymentMethodsCreateSubtitle: 'Card details',
        cardHolder: 'Card holder',
        cardNumber: 'Card number',
        cardExpiry: 'Expiry',
        cardCVC: 'CVC',
        paymentMethodsAddHint: 'Transaction fees will be automatically charged to your payment card.',
        paymentMethodsSetAsDefault: 'Set as default',
        acceptChargesViaStripe:
            'I consent to being charged any applicable subscription fees and transactional fees via Stripe.',
        confirm: 'Confirm',
        paymentMethodsTitle: 'Cards',
        cardExpiresNote: 'Your payment card has expired. Please remove this card and add a new one.',
        expires: 'Expires',
    },
    subscription: {
        subscriptions: 'Subscription',
        packageFree: 'Free',
        packageRuleTransferLimitText: 'free transactions per month',
        packageRuleTransferAmountText: 'additional transaction fee',
        months: 'Months',
        packageRuleHistoryUnlimited: 'Unlimited',
        packageRuleExcelExportText: 'Export to Excel',
        packageRulePushNotificationsText: 'Push Notifications',
        packageRuleHistoryLimitText: 'transaction history',
        subscriptionStatusCurrent: 'Currently Active',
        subscribe: 'Subscribe',
        subscriptionStatusNext: 'Next period',
        nextStartsAt: 'starts at',
        standard: 'Standard',
        premium: 'Premium',
        availableTransaction: 'free transaction remaining',
        subscribed: 'Subscribed',
    },
    month: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec',
    },
};
