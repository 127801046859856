// import InitService from '../../apis/init-service';
// import { isBlank, isEmptyObject, getCurrentTimeToCompare } from '../../utils/helpers';
// import { ILoginFormField, ISignUpFormField } from '../../models/Login/Login';
import StandardResponse from '../../models/standard-response';
import BaseService from '../../apis/base-service';
import { IBankConnect } from '../../models/user/user';

export default class BankService {
    getAllBanks = async () => {
        let response: any = null;
        let error: any = null;

        response = await BaseService.get('afterbanks/accounts').catch((err) => {
            error = err;
        });

        if (error !== null) {
            throw new Error(error);
        }

        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    getUserBanks = async () => {
        let response: any = null;
        let error: any = null;
        response = await BaseService.get('afterbanks/banks').catch((err) => {
            error = err;
        });

        if (error !== null) {
            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    conncetBank = async (data: IBankConnect) => {
        let response: any = null;
        let error: any = null;
        response = await BaseService.post('afterbanks/connect', data).catch((err) => {
            error = err;
        });
        if (error !== null) {
            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data, response.data.message, null);
    };

    disconncetBank = async (id: number) => {
        let response: any = null;
        let error: any = null;
        response = await BaseService.post('afterbanks/disconnect', {
            bank: id,
        }).catch((err) => {
            error = err;
        });
        if (error !== null) {
            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data, response.data.message, null);
    };

    syncBank = async () => {
        let response: any = null;
        let error: any = null;
        response = await BaseService.post('afterbanks/sync', {}).catch((err) => {
            error = err;
        });
        if (error !== null) {
            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data, response.data.message, null);
    };
}
