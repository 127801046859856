import React from 'react';
import SuccessCheckSolidIcon from '../../assets/images/success-check-solid.svg';

const Success = () => {
    return (
        <div className="page-content">
            <div className="success-block">
                <img src={SuccessCheckSolidIcon} alt="" />
                <h6>Success!</h6>
            </div>
        </div>
    );
};

export default Success;
