import BaseService from '../../apis/base-service';
import { isBlank, isEmptyObject, mapUserProfileWithDefaultValues, trimData } from '../../utils/helpers';
import { IUserProfileUpdate, IUserProfile, IUpdateAvatar } from '../../models/user/user';
import StandardResponse from '../../models/standard-response';
import { IForgetPasswordFormField } from '../../models/Login/Login';

export default class UserService {
    getUserDetails = async () => {
        let response: any = null;
        let error: any = null;

        response = await BaseService.get('user/profile').catch((err) => (error = err));

        if (error !== null) {
            throw new Error(error);
        }

        const data: IUserProfile = mapUserProfileWithDefaultValues(response.data.data);

        return new StandardResponse<IUserProfile>(response.status, data, response.data.message, null);
    };

    setUserDetails = async (data: IUserProfileUpdate) => {
        let response: any = null;
        let error: any = null;
        data = trimData(data);

        response = await BaseService.post('user/profile', data).catch((err) => (error = err));

        if (error !== null) {
            throw new Error(error);
        }
        return response;
    };

    getDocuments = async () => {
        let response: any = null;
        let error: any = null;

        response = await BaseService.get('documents').catch((err) => {
            error = err;
        });

        if (error !== null) {
            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    changePassword = async (data: IForgetPasswordFormField) => {
        let response: any = null;
        let error: any = null;
        data = trimData(data);

        response = await BaseService.post('user/password', {
            password: data.OldPassword,
            newPassword: data.ConfirmPassword,
        }).catch((err) => (error = err));

        if (error !== null) {
            return new StandardResponse<IUserProfile>(error.response.status, error.response.data || null, null, error);
        }

        return new StandardResponse<IUserProfile>(response.status, response.data.data, response.data.message, null);
    };

    setAvatar = async (data: IUpdateAvatar) => {
        let response: any = null;
        let error: any = null;

        response = await BaseService.post('user/avatar', data).catch((err) => {
            error = err;
        });

        if (error !== null) {
            throw new Error(error);
        }

        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    userExists = async (email: string) => {
        let response: any = null;
        let error: any = null;

        response = await BaseService.post('user/email-exists', {
            email: trimData(email),
        }).catch((err) => {
            error = err;
        });

        if (!isEmptyObject(response) && error === null) {
            return true;
        } else {
            return false;
        }
    };
}
