import 'react-app-polyfill/ie9';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/dev-style.css';
import './lang';
import UserContextProvider from './context/user/user-context';
import { BrowserRouter as Router } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './components/alert-template/AlertTemplate';

const options = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '20px',
    transition: transitions.SCALE,
};

ReactDOM.render(
    <UserContextProvider>
        <Router basename="/Infin8">
            <AlertProvider template={AlertTemplate} {...options}>
                <App />
            </AlertProvider>
        </Router>
    </UserContextProvider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
