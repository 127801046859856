//language: Portuguese
export default {
    LoginTitle: 'Tudo que você precisa para o seu banco',
    language: 'pt',
    login: {
        loginLanguage: 'língua',
        welcomeMsg: 'Bem-vindo ao Infin8',
        title: 'Conecte-se',
        email: 'Email ou nome de usuário',
        password: 'Senha',
        signupMsg: 'Primeira vez aqui?',
        signup: 'Inscrever-se',
        forgotPassword: 'Esqueceu-se da senha?',
    },
    signup: {
        email: 'Email',
        username: 'nome do usuário',
        fullName: 'Nome completo',
        password: 'Senha',
        phoneNumber: 'Phone Number (including country code with + sign)',
        confirmpassword: 'Confirme a Senha',
        register: 'Registro',
        emailOtp: 'Email OTP',
        phoneOtp: 'Phone OTP',
        backto: 'De volta a',
        registrationSuccessTitle: 'Concluiu com sucesso o registo.',
    },
    signupstep2: {
        step2: 'Passo 2',
        mobileInstruction:
            'Digite o número do seu celular com o código do país (por exemplo, +306944646447) para receber um código de verificação',
        next: 'Próximo',
    },
    notification: {
        notificationTitle: 'Alertas',
    },
    error: {
        validateDifferentAccount: 'Selecione uma conta diferente',
        validatedIban: 'Insira um número IBAN válido',
        Required: 'Requeridas',
        invalidEmail: 'E-mail inválido',
        invalidPhone: 'Incorrect mobile number format',
        emailExist: 'Já existe um utilizador com este e-mail.',
        passwordsDoesntMatch: 'Verificar senhas',
        transactionNoData: 'Nenhuma transação encontrada',
        newPassError: 'Verificar senha',
        newPasswordConfirmInvalid: 'Verifique a confirmação da senha',
        passwordStrongRules:
            'O tamanho da senha tem de ser entre 6 a 16 caracteres e tem de incluir maiúsculas e minúsculas, um número e um destes caracteres especiais: !@#$%&-_)*(',
    },
    individual: 'Individual',
    business: 'O negócio',
    accept: 'Aceitar',
    show: 'Mostrar',
    hide: 'Esconder',
    save: 'Guardar',
    update: 'atualizar',
    select: 'Selecionar',
    cancel: 'Cancelar',
    back: 'Retornar',
    DashboardTitle: 'painel de controle',
    DashboardNoAccounts: 'Não tem contas. Conecte-se com o seu banco e recolha as estatísticas.',
    Expenses: 'Despesas',
    History: 'Histórico',
    Bank: 'Banco',
    AddBank: 'Adicionar banco',
    MyProfile: 'O meu perfil',
    Settings: 'Definições',
    TermsAndCond: 'Termos e condições',
    Logout: 'Terminar sessão',
    TotalAmount: 'Quantia total',
    VatNumber: 'Número de IVA',
    ConnectedBanks: 'Bancos Conectados',
    bankPayments: 'Pagos',
    paymentMethods: 'Tarjetas',
    subscriptionTitle: 'Suscripción',
    News: 'Notícia',
    Dashboard: {
        Tabs: {
            iHave: 'Eu tenho',
            iOwe: 'Eu devo',
            iCanSpend: 'Eu posso gastar',
        },
        balanceAcrossBanks: 'Saldo em seus bancos',
        iHave_transactionalAccountsFilterTitle: 'Contas de transações',
        iHave_savingsAccountFilterTitle: 'Conta de poupanças',
        iHave_termDepositFilterTitle: 'Depósito a prazo',
        iOwe_creditcardsFilterTitle: 'Cartões de crédito',
        iOwe_overdraftsFilterTitle: 'Saque a descoberto',
        iCanSpend_noFeeSpendingFilterTitle: 'Gastos sem taxa',
        iCanSpend_feeSpendingFilterTitle: 'Gastos com taxa',
        iCanSpend_transferToSpendFilterTitle: 'Transferência para gastar',
        iHave_headerMessage: 'Saldos de conta',
        iOwe_headerMessage: 'Valores devidos',
        iCanSpend_headerMessage: 'Disponible para gastar',
    },
    Banks: {
        addBank: {
            reconnectWithPayments: 'Reconectarse con pagos',
            connectAllowPayment: 'Permitir pagos',
            searchPlaceholder: 'Pesquisar',
            title: 'Infin8 - Bancos',
            pageTitle: 'Bancos',
            button: {
                back: 'Costas',
                connected: 'Conectar',
                disconnected: 'desconectar',
                reconnected: 'Reconectar',
                bankLastSyncAt: 'Última Sincronização de Dados',
                paymentSupport: 'Pagos',
            },
            text: {
                connectNote:
                    'Observação importante: seu banco solicitará permissão para se conectar ao Afterbanks LTD, que é nosso provedor de conectividade bancária. Por favor aceite.',
                bankName: 'Nome do banco',
                bankswift: 'Banco Swift',
                countTransaction: 'Transação de contagem',
                countAccount: 'Conta de contagem',
            },
            status: {
                connected: 'Conectado',
                disconnected: 'Desconectado',
                pending: 'Pendente',
                reconnect: 'Reconectar',
            },
        },
    },
    Dates: {
        today: 'Hoje',
        yesterday: 'Ontem',
    },
    Transaction: {
        title: 'Infin8 - Transações',
        last_transactions: 'Últimas Transações',
        transaction_category: 'Categoria de transação',
        description: 'Descrição',
        receipt: 'Recibo',
        selectTransactionCategory: 'Selecionar categoria de transação',
        categoryChangeSuccess: 'categoria alterada com sucesso',
        transferSaveDocument: 'Documento',
        transferSaveImage: 'Imagem',
        transferSaveReceiptAs: 'Guardar ou enviar o recibo como documento ou imagem.',
    },
    TransactionHistory: {
        title: 'Infin8 - Histórico de transações',
        pageTitle: 'Histórico de transações',
        button: {
            filter: 'Filtro',
            clear: 'limpar',
            exportToExcel: 'Exportar para Excel',
        },
        label: {
            dateRange: 'From/To date',
            from: 'De',
            to: 'Para',
            category: 'Categoria',
            bank: 'Banco',
            cardOrAccount: 'Cartão ou conta específica',
            income: 'Rendimentos',
            expense: 'Despesas',
            cashFlow: 'Fluxo de caixa',
        },
    },
    Setting: {
        appSettingsLang: 'Língua da aplicação',
        menuChangePass: 'Alterar senha',
        currentPassword: 'Senha atual',
        newPassword: 'Senha nova',
        newPasswordConfirm: 'Confirme a senha nova',
        passwordChangeSuccess: 'A sua senha foi alterada com sucesso',
    },
    Profile: {
        firstName: 'Nome próprio',
        lastName: 'Apelido',
        email: 'E-mail',
        dateOfBirth: 'Data de nascimento',
        phone: 'telefone',
        sex: 'Sexo',
        postCode: 'Código Postal',
        address: 'Endereço',
        company: 'Companhia',
        sexMale: 'Masculino',
        sexFemale: 'Feminino',
        profileSaved: 'Perfil guardado',
    },
    ExpensesPage: {
        title: 'Infin8 - Despesas',
        pageTitle: 'Despesas',
        button: {
            filter: 'Filtro',
            clear: 'limpar',
            Exportpdf: 'Exportar para pdf',
        },
        label: {
            dateRange: 'From/To date',
            from: 'De',
            to: 'Para',
            category: 'Categoria',
            bank: 'Banco',
            income: 'Rendimento',
            expense: 'Despesas',
            all: 'Todas',
            totalAmount: 'Quantia total',
        },
    },
    forgotPassword: {
        title: 'Esqueceu a senha',
        pageTitle: 'Esqueceu a senha',
        button: {
            continue: 'Continuar',
            save: 'Guardar',
        },
        label: {
            email: 'E-mail',
            password: 'Insira a nova senha',
            confirmpassword: 'Confirme a senha nova',
            forgetPassword: 'Esqueceu a senha',
            otp: 'OTP',
            provideOTP: 'Fornece OTP',
            createNewPassword: 'Criar nova senha',
            otpExpires: 'OTP expira em',
        },
    },
    alertMsg: {
        userPhoneExist: 'o telefone do usuário existe',
        userExist: 'o e-mail do usuário existe',
        wentWrong: 'Algo deu errado. Por favor, tente novamente.',
        wrongOTP: 'OTP errado!',
        otpExpired: 'OTP expirou. Por favor, tente novamente.',
        passwordChangeSuccess: 'Senha alterada com sucesso.',
        userNotFound: 'Usuário não encontrado.',
    },
    receipt: {
        description: 'Descrição',
        date: 'Data',
        amount: 'Quantia',
        category: 'Categoria',
        bank: 'Banco',
        transDetails: 'Detalhes da transação',
    },
    accountTypes: {
        checking: 'Conta corrente',
        debit: 'Conta devedora',
        card: 'Conta do cartão',
        investment: 'Conta de investimentos',
        savings: 'Conta poupança',
    },
    transfer: {
        accountLabelSelect: 'Selecionar conta',
        currentBalance: 'Saldo atual',
        transferScreen: 'Transferencia',
        sourceFrom: 'De',
        sourceTo: 'A',
        amount: 'Quantia',
        transferDescription: 'Descripción',
        continue: 'Continuar',
        acceptPaymentChargesViaStripe: 'Eu aceito cobranças de Stripe',
        transferIBANPlaceholder: 'IBAN',
        transferRecipientPlaceholder: 'Nombre del Recipiente',
        transferConfirmationBottomText: 'Confirmar',
        paymentPrecheckInfo_PAYMENT_METHOD1: 'Su tarjeta será cargada por Infin8 ',
        paymentPrecheckInfo_PAYMENT_METHOD2: 'como costo de transacción. Pueden aplicarse cargos bancarios.',
        transactionRemaining: 'Transações restantes',
        anyIBAN: 'En IBAN',
        ownAccounts: 'En su cuenta',
        iAgree: 'Acepto los',
        paymentIntiation: 'Iniciação de pagamento',
        termsConditions: 'Termos e condições',
        ibanNumber: 'IBAN',
        note: 'Nota',
        transferText:
            'Por favor, tenga en cuenta que el proceso de confirmar y ejecutar la transacción puede tardar hasta 3 días',
    },
    card: {
        paymentMethodsCreateTitle: 'Agregar nueva tarjeta',
        default: 'Predefinida',
        paymentMethodsDeleteButton: 'Eliminar tarjeta',
        paymentMethodsCreateSubtitle: 'Detalles de tarjeta',
        cardHolder: 'Titular de tarjeta',
        cardNumber: 'Número de tarjeta',
        cardExpiry: 'Fecha de caducidad de tarjeta',
        cardCVC: 'CVC',
        paymentMethodsAddHint: 'Las tarifas de transacciones se cargarán automáticamente a su tarjeta',
        paymentMethodsSetAsDefault: 'Establecer por predeterminada',
        acceptChargesViaStripe: 'Acepto cargos a través de paquetes de suscripción Stripe y tarifas de transacción',
        confirm: 'Confirmar',
        paymentMethodsTitle: 'Tarjetas',
        cardExpiresNote: 'Seu cartão de pagamento expirou. Remova este cartão e adicione um novo.',
        expires: 'Expira',
    },
    subscription: {
        subscriptions: 'Suscripción',
        packageFree: 'Gratis',
        packageRuleTransferLimitText: 'Transacciones gratuitas por mes',
        packageRuleTransferAmountText: 'Costo adicional de transacción',
        months: 'Meses',
        packageRuleHistoryUnlimited: 'Ilimitadamente',
        packageRuleExcelExportText: 'Exportar a Excel',
        packageRulePushNotificationsText: 'Alertas push',
        packageRuleHistoryLimitText: 'Historial de transacciones',
        subscriptionStatusCurrent: 'Activado',
        subscribe: 'Registrarse',
        subscriptionStatusNext: 'Próximo período',
        nextStartsAt: 'Comienza desde',
        standard: 'Standard',
        premium: 'Premium',
        availableTransaction: 'Transações gratuitas restantes',
        subscribed: 'Registrarse',
    },

    month: {
        january: 'Janeiro',
        february: 'Fevereiro',
        march: 'Março',
        april: 'Abril',
        may: 'Maio',
        june: 'Junho',
        july: 'Julho',
        august: 'Agosto',
        september: 'Setembro',
        october: 'Outubro',
        november: 'Novembro',
        december: 'Dezembro',
        jan: 'Janeiro',
        feb: 'Fevereiro',
        mar: 'Março',
        apr: 'Abril',
        jun: 'Junho',
        jul: 'Julho',
        aug: 'Agosto',
        sep: 'Setembro',
        oct: 'Outubro',
        nov: 'Novembro',
        dec: 'Dezembro',
    },
};
