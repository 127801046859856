import React, { useState } from 'react';

export const UserContext = React.createContext(null);

const UserContextProvider = (props: any) => {
    const [isAuth, setIsAuth] = useState(false);
    const [userData, setUserData] = useState(null);
    const [allBanks, setAllBanks] = useState([]);
    const [allAccounts, setAllAccounts] = useState(null);

    const values = React.useMemo(() => {
        return {
            authData: [isAuth, setIsAuth],
            userData: [userData, setUserData],
            banksData: [allBanks, setAllBanks],
            accountsData: [allAccounts, setAllAccounts],
        };
    }, [isAuth, userData, allBanks, allAccounts]);

    return <UserContext.Provider value={values}>{props.children}</UserContext.Provider>;
};

export default UserContextProvider;
