export class AuthKeys {
    public static readonly InitToken = 'InitToken';
    public static readonly InitRefreshToken = 'InitRefreshToken';
    public static readonly InitRefreshTokenExpireAt = 'InitRefreshTokenExpireAt';
    public static readonly AuthToken = 'AuthToken';
    public static readonly AuthRefreshToken = 'AuthRefreshToken';
    public static readonly AuthTokenExpireAt = 'AuthTokenExpireAt';
    public static readonly AuthRefreshTokenExpireAt = 'AuthRefreshTokenExpireAt';
    public static readonly ForgetPasswordId = 'ForgetPasswordId';
    public static readonly ForgetPasswordIdExpireAt = 'ForgetPasswordIdExpireAt';
}

export class DashboardContent {
    public static readonly IHave = '1';
    public static readonly IOwe = '2';
    public static readonly ICanSpend = '3';
}

export class RegularExpression {
    public static readonly EmailRegularExpression = new RegExp(
        /^(?=.{1,64}@)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    public static readonly phoneRegExp = new RegExp(/^\+(?:[0-9] ?){6,14}[0-9]$/);
    public static readonly PasswordRegularExpression = new RegExp(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!$%&@)*(_-]).{6,16}$/,
    );
    public static readonly TabsAndNewLineRegularExpression = new RegExp(/[\r\n]/g);
    public static readonly DataPartBase64RegularExpression = new RegExp(/^data:image\/[a-z]+;base64,/);
}

export class Common {
    public static readonly InvalidTokenCode = '0100';
    public static readonly InvalidRefreshTokenCode = '0111';
    public static readonly FallbackLanguage = 'en';
    public static readonly DateFormat = 'DD-MM-YYYY';
    public static readonly DateFormat2 = 'MM-DD-YYYY';
    public static readonly DateWithTimeFormat = 'DD-MM-YYYY HH:mm';
    public static readonly TranDetailsDateFormat = 'MMM DD, YYYY hh:mm';
    public static readonly NoOfTransactions = 3;
    public static readonly DefaultCurrency = 'EUR'; // used for values in headers
    public static readonly DashboardFilterTypes = Object.freeze({
        TRANSACTIONAL_ACCOUNTS: 'TRANSACTIONAL_ACCOUNTS',
        SAVINGS_ACCOUNT: 'SAVINGS_ACCOUNT',
        TERM_DEPOSIT: 'TERM_DEPOSIT',
        CREDIT_CARDS: 'CREDIT_CARDS',
        OVERDRAFTS: 'OVERDRAFTS',
        NO_FEE_SPENDING: 'NO_FEE_SPENDING',
        FEE_SPENDING: 'FEE_SPENDING',
        TRANSFER_TO_SPEND: 'TRANSFER_TO_SPEND',
    });
    public static readonly DocumentTypes = Object.freeze({
        TERM_AND_CONDITIONS: 'terms-and-conditions',
    });
    public static readonly Keys = Object.freeze({
        UserLanguage: 'UserLanguage',
        BanksUpdated: 'BanksUpdated',
        ImageUpdated: 'ImageUpdated',
    });
    public static readonly LanguageCodes = Object.freeze({
        ENGLISH: 'en',
        GREEK: 'el',
        SPANISH: 'es',
        PORTUGUESE: 'pt',
    });
    public static readonly AcceptLanguageCodes = Object.freeze({
        en: 'EN',
        el: 'GR',
        es: 'ES',
        pt: 'PT',
    });
    public static readonly BankStatusSortOrder = Object.freeze({
        connected: 1,
        error: 2,
        pending: 3,
        disconnected: 4,
        other: 5,
    });
}

export class TransactionTypes {
    public static readonly EXPENSE = 'EXPENSE';
    public static readonly INCOME = 'INCOME';
}

export const NaftemporikiRssLFeedURL = 'https://rss.app/feeds/Y1BExTViVLUBJ3G6';
export const financialTimeRssFeedURL = 'https://rss.app/feeds/cIMKaURWkJo0PPyx.xml';

export class TransactionFilterTypes {
    public static readonly EXPENSE = 'EXPENSE';
    public static readonly INCOME = 'INCOME';
    public static readonly ALL = 'ALL';
}

export const BlueShade = () => '#252ED1';

export class ForgotPasswordSteps {
    public static readonly EnterEmail = '1';
    public static readonly EnterOTP = '2';
    public static readonly EnterPassword = '3';
}
