import BaseService from '../../apis/base-service';
import StandardResponse from '../../models/standard-response';
import { ITransferFields } from '../../models/Transfer/Transfer';
import { trimData } from '../../utils/helpers';

export default class TransferService {
    TransferPayment = async (
        amount: number,
        fromAccountId: string,
        toAccountId: string,
        description: string,
        iban: string,
        recipientName: string,
    ) => {
        let response: any = null;
        let error: any = null;

        response = await BaseService.post('afterbanks/payment', {
            successUrl: window.location.href,
            amount: Number(amount),
            sourceId: fromAccountId, //"60a3ae9bf5c6f50308875839",
            currency: 'EUR', //'EUR|USD|GBP',
            type: 'normal', // 'normal|international',
            destinationName: recipientName,
            paymentDescription: description,
            destinationIBAN: iban,
            destinationId: toAccountId, //'60a865b02d0774536e25fe1a',
        }).catch((err) => (error = err));
        if (error !== null) {
            if (error.response && error.response.data && error.response.data.length > 0) {
                const dataObj = error.response.data[0];
                if (dataObj.hasOwnProperty('message')) {
                    throw new Error(dataObj.message);
                }
            }

            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data, response.data.message, null);
    };

    TransferPaymentCheck = async (
        amount: number,
        fromAccountId: string,
        toAccountId: string,
        description: string,
        iban: string,
        recipientName: string,
    ) => {
        let response: any = null;
        let error: any = null;
        response = await BaseService.post('afterbanks/payment/check', {
            successUrl: window.location.href,
            amount: Number(amount),
            sourceId: fromAccountId, //"60a3ae9bf5c6f50308875839",
            currency: 'EUR', //'EUR|USD|GBP',
            type: 'normal', // 'normal|international',
            destinationName: recipientName,
            paymentDescription: description,
            destinationIBAN: iban,
            destinationId: toAccountId, //'60a865b02d0774536e25fe1a',
        }).catch((err) => (error = err));
        if (error !== null) {
            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data, null, null);
    };
}
