import { IUserFullname, IUserProfile } from '../models/user/user';
import { TFunction } from 'i18next';
import currencyToSymbolMap from 'currency-symbol-map/map';
import { Common } from './constants';
import moment from 'moment';

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const isBlank = (data: any) => {
    if (data === null || data === undefined || data.toString().trim() === '') return true;

    return false;
};

export const isEmptyObject = (data: any) => {
    if (data === null || data === undefined || Object.keys(data).length === 0) return true;

    return false;
};

export const isEmptyArray = (data: Array<any>) => {
    if (data === null || data === undefined || data.length === 0) return true;

    return false;
};

export const getValueFromFirstInArray = (data: Array<any>, property: string) => {
    if (data === null || data === undefined || data.length === 0) return null;
    if (data.length > 0 && data[0] !== undefined && data[0].hasOwnProperty(property)) return data[0][property];

    return null;
};

export const getCurrentTimeToCompare = () => {
    const currentTime = new Date();
    currentTime.setSeconds(currentTime.getSeconds() - 2); // 2 for Response delay
    return currentTime;
};

export const mapUserProfileWithDefaultValues = (data: any) => {
    const res: IUserProfile = {
        email: data.email || '',
        address: data.address || '',
        avatar: data.avatar ? process.env.REACT_APP_IMAGE_PATH + data.avatar : '',
        birthDate: data.birthDate || '',
        phone: data.phone || '',
        title: data.title || '',
        gender: data.gender || '',
        phoneVerified: data.phoneVerified || false,
        emailVerified: data.emailVerified || false,
        name: {
            first: data.name && data.name.first ? data.name.first : '',
            last: data.name && data.name.last ? data.name.last : '',
        },
        type: data.type || '',
        businessName: data.businessName || '',
        vatNumber: data.vatNumber || '',
    };
    return res;
};

export const isNumeric = (str: any) => !isNaN(+str);

export const StringToFloat = (str: any) => {
    if (isNumeric(str)) return parseFloat(str);

    return 0;
};

export const addWhiteBg = () => document.body.classList.add('white-bg');

export const removeWhiteBg = () => document.body.classList.remove('white-bg');

export const getFullName = (name: IUserFullname) => name.first + (isBlank(name.last) ? '' : ' ' + name.last);

export const compareStrings = (a: string, b: string) => a.toUpperCase() === b.toUpperCase();

export const numberWithCommas = (x: any) => {
    if (x === null || x === undefined) x = '';
    x = x.toString();
    if (isBlank(x)) return '';

    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isToday = (date: any) => {
    if (isBlank(date)) return false;
    if (!(date instanceof Date)) date = new Date(date);

    const today = new Date();
    return (
        date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
    );
};

export const isYesterday = (date: any) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    return (
        date.getDate() == yesterday.getDate() &&
        date.getMonth() == yesterday.getMonth() &&
        date.getFullYear() == yesterday.getFullYear()
    );
};

export const isSameDay = (d1: any, d2: any) => {
    if (isBlank(d1) || isBlank(d2)) return false;
    if (!(d1 instanceof Date)) d1 = new Date(d1);
    if (!(d2 instanceof Date)) d2 = new Date(d2);

    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

export const isWithinAWeek = (date: Date) => {
    const REFERENCE = moment();
    const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');

    return moment(date).isAfter(A_WEEK_OLD);
};

export const convertDateToMMMMDDYYYYWithComma = (date: any, t: TFunction) => {
    const MONTHS_FULL = [
        `${t('month.january')}`,
        `${t('month.february')}`,
        `${t('month.march')}`,
        `${t('month.april')}`,
        `${t('month.may')}`,
        `${t('month.june')}`,
        `${t('month.july')}`,
        `${t('month.august')}`,
        `${t('month.september')}`,
        `${t('month.october')}`,
        `${t('month.november')}`,
        `${t('month.december')}`,
    ];
    //February 12, 2013
    return MONTHS_FULL[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    // return moment(date.getMonth(), 'MM').format('MMMM') + ' ' + date.getDate() + ', ' + date.getFullYear();
};

export const convertDateToMMDDHHMM = (date: any, t: TFunction) => {
    if (isBlank(date)) return '';
    if (!(date instanceof Date)) date = new Date(date);
    const MONTHS_MMM = [
        `${t('month.jan')}`,
        `${t('month.feb')}`,
        `${t('month.mar')}`,
        `${t('month.apr')}`,
        `${t('month.may')}`,
        `${t('month.jun')}`,
        `${t('month.jul')}`,
        `${t('month.aug')}`,
        `${t('month.sep')}`,
        `${t('month.oct')}`,
        `${t('month.nov')}`,
        `${t('month.dec')}`,
    ];
    //Jun 17 - 14:30
    return MONTHS_MMM[date.getMonth()] + ' ' + date.getDate() + ' - ' + date.getHours() + ':' + date.getMinutes();
    // return moment(date.getMonth(), 'MM').format('MMMM')+ ' ' + date.getDate() + ' - ' + date.getHours() + ':' + date.getMinutes();
};

export const getTransactionDisplayDate = (date: string, t: TFunction) => {
    if (isBlank(date)) return '';
    const d = new Date(date);

    if (isToday(d)) return `${t('Dates.today')}, ${convertDateToMMMMDDYYYYWithComma(d, t)}`;
    if (isYesterday(d)) return `${t('Dates.yesterday')}, ${convertDateToMMMMDDYYYYWithComma(d, t)}`;
    //if (isWithinAWeek(d)) return `Last, ${moment(d).locale('en').format('dddd')}`;

    return convertDateToMMMMDDYYYYWithComma(d, t);
};

export const getAmountWithCurrencySymbol = (amount: number, currency: string) => {
    const value = (currencyToSymbolMap[currency] || '') + numberWithCommas(Math.abs(amount).toFixed(2));
    return Math.sign(amount) === -1 ? '- ' + value : value;
};

export const maskWithAsterisk = (x: any) => {
    if (!isBlank(x) && x.length > 8) {
        const first4 = x.substring(0, 8);
        const last4 = x.substring(x.length - 4);
        const mask = '****';
        return first4 + mask + last4;
    }
    return x;
};

export const cardMaskWithAsterisk = (x: any) => {
    if (!isBlank(x)) {
        const last4 = x.substring(x.length - 4);
        const mask = '****';
        return mask + ' ' + last4;
    }
    return x;
};

export const partialSearch = (a: string, b: string) => {
    if (isBlank(a) || isBlank(b)) return false;
    return a.includes(b);
};

export const trimData = (a: any) => {
    if (typeof a === 'string') {
        return a.trim();
    }

    if (typeof a === 'object') {
        for (const i in a) {
            if (typeof i === 'string') {
                a[i] = a[i].trim();
            }
        }
    }

    return a;
};

export const getConnectionStatus = (connectionStatus: any, t: TFunction) => {
    if (connectionStatus === undefined) return t('Banks.addBank.status.disconnected');
    if (connectionStatus === 'connected') return t('Banks.addBank.status.connected');
    if (connectionStatus === 'panding') return t('Banks.addBank.status.pending');
    if (connectionStatus === 'error') return t('Banks.addBank.status.disconnected');
    return '';
};
