export const loadStorage = (key: string) => {
    const serializedStore: any = sessionStorage.getItem(key);
    try {
        if (serializedStore === null) {
            return undefined;
        }
    } catch (error) {
        console.log('Error in getting LocalStorage State!!!');
    }
    return JSON.parse(serializedStore);
};

export const saveStorage = (key: string, data: any) => {
    try {
        removeStorage(key);
        return sessionStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
        console.log('Error in Saving LocalStorage State!!!');
    }
};

export const removeStorage = (key: string) => {
    try {
        return sessionStorage.removeItem(key);
    } catch (error) {
        console.log('Error in Removing LocalStorage State!!!');
    }
};
