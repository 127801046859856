import React from 'react';
import { Form } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { ErrorMessage } from 'formik';
import { isFirefox } from 'react-device-detect';
import YearMonthForm from './YearMonthForm';
import { Common } from '../../utils/constants';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import { loadStorage } from '../../utils/localStroage';

import 'moment/locale/el';
import 'moment/locale/es';
import 'moment/locale/pt';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, new Date().getMonth());

class ReactDatePickerInput extends React.Component<any> {
    constructor(props: any) {
        super(props);
        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
        this.state = {
            month: fromMonth,
        };
    }

    handleYearMonthChange = (month) => {
        this.setState({ month });
    };

    updateInputRef = (ref) => {
        if (ref && isFirefox) ref.input.focus = function () {};
    };

    render() {
        const {
            id,
            label,
            fromValue,
            toValue,
            fromDayPickerProps,
            todayPickerProps,
            fromDayChange,
            toDayChange,
            ...rest
        } = this.props;

        const fields = { ...this.props.field };

        return (
            <>
                <Form.Group controlId={id}>
                    {this.props.label && <Form.Label className={'label-color'}>{this.props.label}</Form.Label>}
                    <DayPickerInput
                        ref={this.updateInputRef}
                        format={Common.DateFormat}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        {...fields}
                        {...rest}
                        value={fromValue}
                        inputProps={{
                            ...this.props.inputProps,
                            className: 'form-control',
                        }}
                        classNames={{
                            container: 'displayBlock DatePickerInput',
                            overlay: '',
                        }}
                        dayPickerProps={{
                            locale: loadStorage(Common.Keys.UserLanguage),
                            localeUtils: MomentLocaleUtils,
                            ...fromDayPickerProps,
                            month: this.state.month,
                            captionElement: ({ date, localeUtils }) => (
                                <YearMonthForm
                                    date={date}
                                    localeUtils={localeUtils}
                                    onChange={this.handleYearMonthChange}
                                />
                            ),
                        }}
                        onDayChange={(value) => this.props.fromDayChange(value)}
                    />

                    <ErrorMessage name={fields.name}>
                        {(msg) =>
                            msg !== 'required' ? (
                                <Form.Control.Feedback className="pb-0 error invalid-feedback d-block" type="invalid">
                                    {msg}
                                </Form.Control.Feedback>
                            ) : (
                                ''
                            )
                        }
                    </ErrorMessage>
                </Form.Group>
            </>
        );
    }
}

export default ReactDatePickerInput;
