//language: Greek
export default {
    language: 'el',
    LoginTitle: 'Ο,τι χρειάζεσαι για τις τραπεζικές σου ανάγκες',
    login: {
        loginLanguage: 'Γλώσσα',
        welcomeMsg: 'Καλώς ήλθατε στο Infin8',
        title: 'Σύνδεση',
        email: 'E-mail ή το όνομα χρήστη',
        password: 'Κωδικός πρόσβασης',
        signupMsg: 'Πρώτη φορά εδώ?',
        signup: 'Εγγραφείτε',
        forgotPassword: 'Ξέχασες τον κωδικό πρόσβασης;',
    },
    signup: {
        email: 'Email',
        username: 'όνομα χρήστη',
        fullName: 'Ονοματεπώνυμο',
        password: 'Κωδικός πρόσβασης',
        phoneNumber: 'Είσαγε το κινητό σου με τον κωδικό χώρας +30 για Ελλάδα για να λάβεις τον κωδικό επιβεβαίωσης',
        confirmpassword: 'Επιβεβαίωση Κωδικού',
        register: 'Εγγραφή',
        emailOtp: 'Κωδικός επιβεβαίωσης από email',
        phoneOtp: 'Κωδικός επιβεβαίωσης από κινητό',
        backto: 'Πίσω',
        registrationSuccessTitle: 'Η εγγραφή σου ολοκληρώθηκε επιτυχώς.',
    },
    signupstep2: {
        step2: 'Βήμα 2',
        mobileInstruction:
            'Εισαγάγετε τον αριθμό του κινητού σας με κωδικό χώρας (π.χ. +306944646447) για να λάβετε έναν κωδικό επαλήθευσης',
        next: 'Επόμενο',
    },
    notification: {
        notificationTitle: 'ειδοποιήσεις',
    },
    error: {
        validateDifferentAccount: ' Παρακαλώ επιλέξτε διαφορετικό λογαριασμό',
        validatedIban: 'Παρακαλώ εισάγετε έναν έγκυρο αριθμό ΙΒΑΝ',
        Required: 'Το πεδίο είναι υποχρεωτικό',
        invalidEmail: 'Ακυρη διεύθυνση ηλεκτρονικού ταχυδρομείου',
        invalidPhone: 'Λάνθασμένη μορφή αριθμού κινητού',
        emailExist: 'Υπάρχει ήδη χρήστης με αυτό το email.',
        passwordsDoesntMatch: 'Έλεγξε τον κωδικό πρόσβασης',
        transactionNoData: 'Δεν βρέθηκαν συναλλαγές',
        newPassError: 'Ελέγξτε τον κωδικό πρόσβασης',
        newPasswordConfirmInvalid: 'Έλεγξε την επιβεβαίωση κωδικού πρόσβασης',
        passwordStrongRules:
            'Ο κωδικός πρόσβασης πρέπει να είναι 6-16 χαρακτηρες, να περιέχει μικρά και κεφαλαία γράμματα, τουλάχιστον ένα νούμερο και ένα ειδικό χαρακτήρα: !@#$%&-_)*(',
    },
    individual: 'Ατομο',
    business: 'Επιχείρηση',
    accept: 'Αποδοχή',
    show: 'Δείξε',
    hide: 'Κρύψε',
    save: 'Αποθήκευση',
    update: 'εκσυγχρονίζω',
    select: 'Επίλεξε',
    cancel: 'Ακύρωση',
    back: 'Πίσω',
    DashboardTitle: 'Σύνοψη',
    DashboardNoAccounts: 'Δεν έχεις λογαριασμούς. Παρακαλώ σύνδεσε την τράπεζα σου',
    Expenses: 'Συναλλαγές',
    History: 'Ιστορικότητα',
    Bank: 'Τράπεζα',
    AddBank: 'Πρόσθεσε',
    MyProfile: 'Το προφίλ μου',
    Settings: 'Ρυθμίσεις',
    TermsAndCond: 'Όροι & Προϋποθέσεις',
    Logout: 'Έξοδος χρήστη',
    TotalAmount: 'Συνολικό Ποσό',
    VatNumber: 'Αριθμός ΦΠΑ',
    ConnectedBanks: 'Συνδεδεμένες τράπεζες',
    bankPayments: 'Πληρωμές',
    paymentMethods: 'Κάρτες',
    subscriptionTitle: 'Συνδρομή',
    News: 'Νέα',
    Dashboard: {
        Tabs: {
            iHave: 'Έχω',
            iOwe: 'Χρωστάω',
            iCanSpend: 'Μπορώ να ξοδέψω',
        },
        balanceAcrossBanks: 'Υπόλοιπο στις τράπεζες σας',
        iHave_transactionalAccountsFilterTitle: 'Λογαριασμοί Όψεως',
        iHave_savingsAccountFilterTitle: 'Λογαριασμοί Ταμιευτηρίου',
        iHave_termDepositFilterTitle: 'Προθεσμιακές Καταθέσεις',
        iOwe_creditcardsFilterTitle: 'Πιστωτικές Κάρτες',
        iOwe_overdraftsFilterTitle: 'Υπερανάληψη',
        iCanSpend_noFeeSpendingFilterTitle: 'Δαπάνες χωρίς χρέωση',
        iCanSpend_feeSpendingFilterTitle: 'Δαπάνες με χρέωση',
        iCanSpend_transferToSpendFilterTitle: 'Μετέφερε για να ξοδέψεις',
        iHave_headerMessage: 'Υπόλοιπα Λογαριασμών',
        iOwe_headerMessage: 'Υπόλοιπα Καρτών',
        iCanSpend_headerMessage: 'Διαθέσιμα για να ξοδέψεις',
    },
    Banks: {
        addBank: {
            reconnectWithPayments: 'Επανασυνδέσου με πληρωμές',
            connectAllowPayment: 'Να επιτρέπονται πληρωμές',
            searchPlaceholder: 'Ψάξε',
            title: 'Infin8 - Τράπεζες',
            pageTitle: 'Τράπεζες',
            button: {
                back: 'Πίσω',
                connected: 'Σύνδεση',
                disconnected: 'Αποσύνδεση',
                reconnected: 'Επανασύνδεση',
            },
            text: {
                connectNote:
                    'Σημαντική σημείωση: Η τράπεζά σας θα ζητήσει άδεια για σύνδεση με την Afterbanks LTD, η οποία είναι ο πάροχος μας για συνδεσιμότητα τράπεζας. Παρακαλώ αποδεχτείτε το.',
                bankName: 'Ονομα τράπεζας',
                bankswift: 'SWIFT ΤΡΑΠΕΖΑΣ',
                countTransaction: 'ΑΡΙΘΜΟΣ ΣΥΝΑΛΛΑΓΩΝ',
                countAccount: 'ΑΡΙΘΜΟΣ ΛΟΓΑΡΙΑΣΜΩΝ',
                paymentSupport: 'ΥΠΟΣΤΗΡΙΞΗ ΠΛΗΡΩΜΩΝ',
                bankLastSyncAt: 'ΤΕΛΕΥΤΑΙΟΣ ΣΥΓΧΡΟΝΙΣΜΟΣ',
            },
            status: {
                connected: 'Συνδεδεμένη',
                disconnected: 'Αποσυνδεδεμένη',
                pending: 'εκκρεμής',
                reconnect: 'Επανασύνδεση',
            },
        },
    },
    Dates: {
        today: 'Σήμερα',
        yesterday: 'Εχθές',
    },
    Transaction: {
        title: 'Infin8 - Τελευταίες',
        last_transactions: 'Τελευταίες συναλλαγές',
        transaction_category: 'Κατηγορία συναλλαγών',
        description: 'Περιγραφή',
        receipt: 'Απόδειξη',
        selectTransactionCategory: 'Επέλεξε κατηγορία συναλλαγής',
        categoryChangeSuccess: 'η κατηγορία άλλαξε με επιτυχία',
        transferSaveDocument: 'Έγγραφο',
        transferSaveImage: 'Εικόνα',
        transferSaveReceiptAs: 'Σώσε ή στείλε την απόδειξη σαν έγγραφο ή εικόνα.',
    },
    TransactionHistory: {
        title: 'Infin8 - Ιστορικότητα Συναλλαγών',
        pageTitle: 'Ιστορικότητα Συναλλαγών',
        button: {
            filter: 'Φίλτρο',
            clear: 'Καθαρισμός',
            exportToExcel: 'Εξαγωγή στο Excel',
        },
        label: {
            dateRange: 'From/To date',
            from: 'Από',
            to: 'Σε',
            category: 'Κατηγορία',
            bank: 'Τράπεζα',
            cardOrAccount: 'Κάρτα ή λογαριασμός',
            income: 'Έσοδα',
            expense: 'Έξοδα',
        },
    },
    Setting: {
        appSettingsLang: 'Γλώσσα Εφαρμογής',
        menuChangePass: 'Αλλαγή κωδικού πρόσβασης',
        currentPassword: 'Ο τρέχων κωδικός πρόσβασης',
        newPassword: 'Νέος κωδικός πρόσβασης',
        newPasswordConfirm: 'Επιβεβαίωσε τον νέο κωδικό πρόσβασης',
        passwordChangeSuccess: 'Ο κωδικός πρόσβασης άλλαξε επιτυχώς',
    },
    Profile: {
        firstName: 'Όνομα',
        lastName: 'Επίθετο',
        email: 'E-mail',
        dateOfBirth: 'Ημερομηνία γέννησης',
        phone: 'Τηλέφωνο',
        sex: 'Φύλο',
        postCode: 'ΤΑΧΥΔΡΟΜΙΚΟΣ ΚΩΔΙΚΟΣ',
        address: 'Διεύθυνση',
        company: 'Εταιρία',
        sexMale: 'Άνδρας',
        sexFemale: 'Γυναίκα',
        profileSaved: 'Το προφίλ αποθηκεύτηκε',
    },
    ExpensesPage: {
        title: 'Infin8 - Συναλλαγές',
        pageTitle: 'Συναλλαγές',
        button: {
            filter: 'Φίλτρο',
            clear: 'Καθαρισμός',
            Exportpdf: 'Εξαγωγή σε pdf',
        },
        label: {
            dateRange: 'From/To date',
            from: 'Από',
            to: 'Σε',
            category: 'Κατηγορία',
            bank: 'Τράπεζα',
            income: 'Έσοδα',
            expense: 'Έξοδα',
            all: 'Όλα',
            totalAmount: 'Συνολικό Ποσό',
            cashFlow: 'Ταμειακή Ροή',
        },
    },
    forgotPassword: {
        title: 'Ξεχάσατε τον κωδικό',
        pageTitle: 'Ξεχάσατε τον κωδικό',
        button: {
            continue: 'Συνέχεια',
            save: 'Αποθήκευση',
        },
        label: {
            email: 'Email',
            password: 'Εισάγετε νέο κωδικό',
            confirmpassword: 'Επιβεβαίωσε τον νέο κωδικό πρόσβασης',
            forgetPassword: 'Ξεχάσατε τον κωδικό',
            otp: 'OTP',
            provideOTP: 'Παροχή OTP',
            createNewPassword: 'Δημιουργήστε νέο κωδικό πρόσβασης',
            otpExpires: 'Το OTP λήγει στις',
        },
    },
    alertMsg: {
        userPhoneExist: 'το τηλέφωνο χρήστη υπάρχει',
        userExist: 'το email χρήστη είναι Exist',
        wentWrong: 'Κάτι πήγε στραβά. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα.',
        wrongOTP: 'Λάθος OTP!',
        otpExpired: 'Το OTP έληξε. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα.',
        passwordChangeSuccess: 'Ο κωδικός άλλαξε επιτυχώς',
        userNotFound: 'Ο χρήστης δεν βρέθηκε.',
    },
    receipt: {
        description: 'Περιγραφή',
        date: 'Ημερομηνία',
        amount: 'Ποσό',
        category: 'Κατηγορία',
        bank: 'Τράπεζα',
        transDetails: 'Λεπτομέρειες  Συναλλαγής',
    },
    accountTypes: {
        checking: 'Λογαριασμός όψεως',
        debit: 'Χρεωστικός λογαριασμός',
        card: 'Λογαριασμός κάρτας',
        investment: 'Επενδυτικός λογαριασμός',
        savings: 'Λογαριασμός ταμιευτηριου',
    },
    transfer: {
        accountLabelSelect: 'Διάλεξε Λογαριασμό',
        currentBalance: 'Υπόλοιπο',
        transferScreen: 'Μεταφορά',
        sourceFrom: 'Από',
        sourceTo: 'Προς',
        amount: 'Ποσό',
        transferDescription: 'Περιγραφή',
        continue: 'Συνέχεια',
        acceptPaymentChargesViaStripe: 'Αποδέχομαι χρεώσεις πέραν του ορίου δωρεάν συναλλαγών',
        transferIBANPlaceholder: 'IBAN',
        transferRecipientPlaceholder: 'Όνομα παραλήπτη',
        transferConfirmationBottomText: 'Επιβεβαίωση',
        paymentPrecheckInfo_PAYMENT_METHOD1: 'Η κάρτα σου θα χρεωθεί από την Infin8 ',
        paymentPrecheckInfo_PAYMENT_METHOD2: 'ως κόστος συναλλαγής. Μπορεί να ισχύουν χρεώσεις τραπεζών.',
        transactionRemaining: 'Υπολοιπόμενες συναλλαγές',
        anyIBAN: 'Σε IBAN',
        ownAccounts: 'Σε λογαριασμό σου',
        iAgree: 'Αποδέχομαι τους',
        paymentIntiation: 'Όρους και ',
        termsConditions: 'Προυποθέσεις Πληρωμών ',
        ibanNumber: 'IBAN',
        note: 'Σημείωση',
        transferText:
            'Παρακαλώ λάβετε υπόψη σας ότι μπορεί να πάρει εως 3 ημέρες η επιβεβαίωση και εκτέλεση της συναλλαγής',
    },
    card: {
        paymentMethodsCreateTitle: 'Πρόσθεσε νέα κάρτα',
        default: 'Προκαθορισμένη',
        paymentMethodsDeleteButton: 'Διέγραψε την κάρτα',
        paymentMethodsCreateSubtitle: 'Λεπτομέρειες κάρτας',
        cardHolder: 'Κάτοχος κάρτας',
        cardNumber: 'Αριθμός κάρτας',
        cardExpiry: 'Ημερομηνία λήξης κάρτας',
        cardCVC: 'CVC',
        paymentMethodsAddHint: 'Οι προμήθειες συναλλαγών θα χρεωθούν αυτόματα στην κάρτα σου',
        paymentMethodsSetAsDefault: 'Ορισμός ως προεπιλεγμένης',
        acceptChargesViaStripe: 'Αποδέχομαι τις χρεώσεις συνδρομητικών πακέτων και προμηθειών συναλλαγών ',
        confirm: 'Επιβεβαίωση',
        paymentMethodsTitle: 'Κάρτες',
        cardExpiresNote: 'Η κάρτα σας έχει λήξει. Παρακαλώ αφαιρέστε την και προσθέστε νέα κάρτα',
        expires: 'Λήγει',
    },
    subscription: {
        subscriptions: 'Συνδρομή',
        packageFree: 'Δωρεάν',
        packageRuleTransferLimitText: 'Δωρεάν συναλλαγές το μήνα',
        packageRuleTransferAmountText: 'Επιπλεόν κόστος συναλλαγής',
        months: 'Μήνες',
        packageRuleHistoryUnlimited: 'Απεριόριστα',
        packageRuleExcelExportText: 'Εξαγωγή σε Excel',
        packageRulePushNotificationsText: 'Push ειδοποιήσεις',
        packageRuleHistoryLimitText: 'Ιστορικότητα συναλλαγών',
        subscriptionStatusCurrent: 'Ενεργό',
        subscribe: 'Εγγραφή',
        subscriptionStatusNext: 'Επόμενη περίοδος',
        nextStartsAt: 'Αρχίζει από',
        standard: 'Standard',
        premium: 'Premium',
        availableTransaction: 'Υπολοιπόμενες δωρεάν συναλλαγές',
        subscribed: 'Εγγραφή',
    },

    month: {
        january: 'Ιανουαρίου',
        february: 'Φεβρουαρίου',
        march: 'Μαρτίου',
        april: 'Απριλίου',
        may: 'Μαΐου',
        june: 'Ιουνίου',
        july: 'Ιουλίου',
        august: 'Αυγούστου',
        september: 'Σεπτεμβρίου',
        october: 'Οκτωβρίου',
        november: 'Νοεμβρίου',
        december: 'Δεκεμβρίου',
        jan: 'Ιανουαρίου',
        feb: 'Φεβρουαρίου',
        mar: 'Μαρτίου',
        apr: 'Απριλίου',
        jun: 'Ιουνίου',
        jul: 'Ιουλίου',
        aug: 'Αυγούστου',
        sep: 'Σεπτεμβρίου',
        oct: 'Οκτωβρίου',
        nov: 'Νοεμβρίου',
        dec: 'Δεκεμβρίου',
    },
};
