import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../context/user/user-context';

const AuthRoute = ({ component: Component, ...rest }) => {
    const contextData = useContext(UserContext);
    const [isAuth, setIsAuth] = contextData.authData;

    return <Route {...rest} render={(props) => (isAuth ? <Component {...props} /> : null)} />;
};

export default AuthRoute;
