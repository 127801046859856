import React from 'react';
import { Form } from 'react-bootstrap';

const ReactstrapFormikRadio = ({ field: { ...fields }, ...props }) => {
    const { id, label, ...rest } = props;
    return (
        <Form.Group controlId={id}>
            <div className="custom-control custom-radio">
                <input type="radio" className="custom-control-input" id={id} {...fields} {...rest} />
                <label className="custom-control-label pb-0" htmlFor={id}>
                    {label}
                </label>
            </div>
        </Form.Group>
    );
};
export default ReactstrapFormikRadio;
