import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Field, Formik, isEmptyArray } from 'formik';
import { ReactSelectBox, ReactstrapInput } from '../../components/reactstrap-formik';
import { RouteProps } from 'react-router-dom';
import { Button, Form, ToggleButtonGroup } from 'react-bootstrap';

import SuccessCheckSolidIcon from '../../assets/images/success-check-solid.svg';
import ChevronRightArrowSolidIcon from '../../assets/images/chevron-right-arrow-solid-dark.svg';
import { ITransferState } from '../../types/stateTypes';
import { ITransferProps } from '../../types/propTypes';
import { ITransferFields } from '../../models/Transfer/Transfer';
import BankService from '../../services/bank/bank-service';
import TransferService from '../../services/Transfer/transfer-service';
import SubscriptionService from '../../services/subscriptions/subscriptions-service';
import { UserContext } from '../../context/user/user-context';
import * as Yup from 'yup';
import { withAlert } from 'react-alert';
import CardPaymentService from '../../services/payment/Payment-service';
import { getAmountWithCurrencySymbol } from '../../utils/helpers';
import firebase from '../../firebase/firebase';
import { Common } from '../../utils/constants';
import { loadStorage } from '../../utils/localStroage';
class Transfer extends React.Component<RouteProps & WithTranslation> {
    goBackHandler = () => this.props.history.goBack();
    bankService: BankService;
    transferService: TransferService;
    subscriptionService: SubscriptionService;
    state: ITransferState;
    cardPaymentService: CardPaymentService;
    static contextType = UserContext;
    analytics = firebase.analytics();
    private initialState: ITransferFields = {
        fromAccountId: '',
        toAccountId: '',
        IBAN: '',
        Amount: undefined,
        Description: '',
        recipientName: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            fromAccountList: [],
            toAccountList: [],
            accountType: false,
            confirmBox: false,
            success: false,
            fromAccountName: '',
            fromAccountAmount: '',
            fromAccountId: '',
            toAccountName: '',
            toAccountAmount: '',
            toAccountId: '',
            tranfersPending: 0,
            subscriptionsPackage: [],
            card: null,
            transferPriceOverLimit: null,
            conditionCheck: false,
            paymentCheck: false,
            transferLimit: 0,
        };
        this.bankService = new BankService();
        this.transferService = new TransferService();
        this.subscriptionService = new SubscriptionService();
        this.cardPaymentService = new CardPaymentService();
    }
    AmountRegExp = /^(\d+|\d{1,3}(,\d{1,3})*)(\.\d{1,2})?$/;
    IBANRegExp = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/; // For All Country
    //IBANRegExp = /^GR\d{9}[0-9A-Z]{16}$/; // For Greece Country
    DescriptionRegExp = /^[\p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+){0-9}{/}}]*$/gu;

    ValidationSchema = Yup.object().shape({
        IBAN: Yup.string()
            .matches(this.IBANRegExp, this.props.t('error.validatedIban'))
            .required(this.props.t('error.Required')),
        Amount: Yup.string()
            .matches(this.AmountRegExp, 'Please insert valid Amount')
            .required(this.props.t('error.Required')),
        Description: Yup.string()
            .matches(this.DescriptionRegExp, 'Please insert valid Description')
            .required(this.props.t('error.Required')),
    });
    Validations = Yup.object().shape({
        Amount: Yup.string()
            .matches(this.AmountRegExp, 'Please insert valid Amount')
            .required(this.props.t('error.Required')),
        Description: Yup.string()
            .matches(this.DescriptionRegExp, 'Please insert valid Description')
            .required(this.props.t('error.Required')),
    });
    componentDidMount = async () => {
        let allBank = [];
        //let subscriptionPlan = [];
        if (!isEmptyArray(this.context.banksData[0])) {
            allBank = [...this.context.banksData[0]];
        } else {
            await this.bankService
                .getUserBanks()
                .then((res) => {
                    allBank = [...res.Data];
                })
                .catch((err) => {});
        }
        this.subscriptionService
            .getUserSubscription()
            .then((res) => {
                const subscriptionPlan = res.Data;
                this.setState({
                    transfersLimit: subscriptionPlan.conditions.transfersLimit,
                });
                this.setState({
                    tranfersPending: subscriptionPlan.conditions.transfersLimit - subscriptionPlan.counts.tranfers,
                });
                this.setState({
                    transferPriceOverLimit: subscriptionPlan.conditions.transferPriceOverLimit,
                });
                this.setState({ subscriptionsPackage: subscriptionPlan.package });
            })
            .catch((err) => {});
        const [allAccounts, setAllAccounts] = this.context.accountsData;
        if (allAccounts === null) {
            const loadAccounts = setInterval(() => {
                if (this.context.accountsData[0] !== null) {
                    //set state
                    const accData = this.context.accountsData[0];
                    const fromAccountData = accData.filter((allb) => allb.tokenType == 'payment');
                    this.setState({ toAccountList: this.getBankAccounts(accData, allBank) });
                    this.setState({ fromAccountList: this.getBankAccounts(fromAccountData, allBank) });
                    clearInterval(loadAccounts);
                }
            }, 100);
        } else {
            const fromAccountData = allAccounts.filter((allb) => allb.tokenType == 'payment');
            this.setState({ toAccountList: this.getBankAccounts(allAccounts, allBank) });
            this.setState({ fromAccountList: this.getBankAccounts(fromAccountData, allBank) });
        }
        this.cardPaymentService
            .getCards()
            .then((res) => {
                res.Data.map((card) => {
                    if (card.default === true) {
                        this.setState({ card: true });
                    }
                });
            })
            .catch((err) => {
                this.props.alert.error(err.toString());
            });
    };

    getBankAccounts = (accData, allBank) => {
        return accData.map((bank) => {
            const bName = allBank.filter((allb) => allb.service == bank.bankId);
            const acc = bank.accounts.filter((allb) => !['card', 'debit'].includes(allb.type));
            return {
                // label: !isEmptyArray(bName) ? bName[0].fullname : bank.bankId,
                options: acc.map((_) => {
                    return {
                        id: _._id,
                        name: (
                            <div className="bank-checking-summary-detail">
                                <div>
                                    <span className="semi-bold">
                                        {!isEmptyArray(bName) ? bName[0].fullname : bank.bankId}
                                    </span>
                                    <span className="grey-text">{_.number}</span>
                                </div>
                                <div className="text-right">
                                    <span className="grey-text text-capitalize">
                                        {this.props.t('transfer.currentBalance')}
                                    </span>{' '}
                                    <span>{getAmountWithCurrencySymbol(_.balance, _.currency)}</span>
                                </div>
                            </div>
                        ),
                        numberOriginal: _.number,
                        bankName: !isEmptyArray(bName) ? bName[0].fullname : bank.bankId,
                        balance: _.balance,
                    };
                }),
            };
        });
        // .sort((a, b) => {
        //     return a.label.toUpperCase() < b.label.toUpperCase()
        //         ? -1
        //         : a.label.toUpperCase() > b.label.toUpperCase()
        //         ? 1
        //         : 0;
        // });
    };
    swapAccount = (value, e) => {
        this.setState({ accountType: value });
    };

    confirmDetail = (value, e) => {
        this.setState({ confirmBox: !this.state.confirmBox });
        this.initialState.Amount = undefined;
        this.initialState.fromAccountId = '';
        this.initialState.toAccountId = '';
        this.initialState.Description = '';
        this.initialState.IBAN = '';
        this.initialState.recipientName = '';
        this.conditionCheck();
        this.paymentCheck();
    };
    conditionCheck = () => {
        this.setState({ conditionCheck: !this.state.conditionCheck });
    };

    paymentCheck = () => {
        this.setState({ paymentCheck: !this.state.paymentCheck });
    };
    paymentDetailSubmit = (values) => {
        const Amount = values.Amount.split(',').join('');
        values.Amount = Amount;
        values.fromAccountId.id === values.toAccountId.id
            ? this.props.alert.info(this.props.t('error.validateDifferentAccount'))
            : this.transferService
                  .TransferPaymentCheck(
                      Amount,
                      values.fromAccountId.id,
                      values.toAccountId.id,
                      values.Description,
                      values.IBAN,
                      values.recipientName,
                  )
                  .then((res) => {
                      if (res !== null) {
                          //   console.log(values);
                          this.props.history.push('/transferConfirm', {
                              amount: Amount,
                              fromAccountId: values.fromAccountId.id,
                              toAccountId: values.toAccountId.id,
                              description: values.Description,
                              iban: values.IBAN,
                              recipientName: values.recipientName,
                              fromAccountName: values.fromAccountId.bankName,
                              fromAccountAmount: values.fromAccountId.balance,
                              toAccountName: values.toAccountId.bankName,
                              toAccountAmount: values.toAccountId.balance,
                          });
                          //   this.setState({ confirmBox: !this.state.confirmBox });
                      }
                  })
                  .catch(() => {});
        this.initialState.Amount = Amount;
        this.initialState.fromAccountId = values.fromAccountId.id;
        this.initialState.toAccountId = values.toAccountId.id;
        this.initialState.Description = values.Description;
        this.initialState.IBAN = values.IBAN;
        this.initialState.recipientName = values.recipientName;
    };
    formatNumber = (inputNumber) => {
        const nf = new Intl.NumberFormat();
        const formattedText = inputNumber.split(',').join('');
        const splitArray = formattedText.split('.');
        const formetedNumber = nf.format(splitArray[0]);
        if (splitArray.length > 1) {
            return formetedNumber + '.' + splitArray[splitArray.length - 1];
        } else {
            return formetedNumber;
        }
    };
    render() {
        const customDropdown = {
            control: () => ({}),
            menu: (provided, state) => ({
                ...provided,
                width: '100%',
            }),
        };
        const { fromAccountList, toAccountList } = this.state;
        const { subscriptionsPackage } = this.state;
        return (
            <>
                <Helmet>
                    <title>{this.props.t('transfer.transferScreen')}</title>
                </Helmet>

                {/* Page Content */}
                <div className="page-content">
                    {/* Page Content Heading */}
                    <div className="mb-4">
                        <h4 className="pb-0 semi-bold blue-text">
                            <p>
                                {this.props.t('transfer.transferScreen')}
                                <span className="transfer-count note-statement fs-14 mb-2">
                                    {this.state.tranfersPending < 0 ? 0 : this.state.tranfersPending}{' '}
                                    {this.props.t('transfer.transactionRemaining')}
                                </span>
                            </p>
                        </h4>
                    </div>

                    {this.state.accountType === false ? (
                        <Formik
                            initialValues={this.initialState}
                            onSubmit={this.paymentDetailSubmit}
                            validationSchema={this.Validations}
                        >
                            {(props) => {
                                const {
                                    handleSubmit,
                                    values,
                                    setFieldValue,
                                    resetForm,
                                    submitForm,
                                    errors,
                                    touched,
                                } = props;

                                return (
                                    <form onSubmit={handleSubmit} noValidate>
                                        {/* Tranfer from own account page start */}
                                        <div className="bank-transfer-selection">
                                            <div className="bank-transfer-single-selection">
                                                <label className="selection-control-label">
                                                    {this.props.t('transfer.sourceFrom')}
                                                </label>
                                                <Field
                                                    name="fromAccountId"
                                                    value={values.fromAccountId}
                                                    options={fromAccountList}
                                                    readOnly
                                                    onChange={(value) => {
                                                        setFieldValue('fromAccountId', value !== null ? value : []);
                                                        this.initialState.fromAccountId = value;
                                                        value !== null
                                                            ? this.setState({
                                                                  fromAccountId: value.numberOriginal,
                                                              })
                                                            : this.setState({ fromAccountId: '' });
                                                        value !== null
                                                            ? this.setState({
                                                                  fromAccountAmount: value.balance,
                                                              })
                                                            : this.setState({ fromAccountAmount: '' });
                                                        value !== null
                                                            ? this.setState({
                                                                  fromAccountName: value.bankName,
                                                              })
                                                            : this.setState({ fromAccountName: '' });
                                                    }}
                                                    isMulti={false}
                                                    isInvalid={false}
                                                    isClearable={true}
                                                    getOptionLabel={(opt) => opt.name}
                                                    getOptionValue={(opt) => opt.id}
                                                    placeholder={this.props.t('transfer.accountLabelSelect')}
                                                    component={ReactSelectBox}
                                                    className="custom-dropdown custom-dropdown-height mb-3"
                                                    styles={customDropdown}
                                                />
                                            </div>
                                            <i className="separator">
                                                <img src={ChevronRightArrowSolidIcon} alt="" />
                                            </i>
                                            <div className="bank-transfer-single-selection">
                                                <div className="row">
                                                    <div className="col">
                                                        <span className="selection-control-label">
                                                            {this.props.t('transfer.sourceTo')}
                                                        </span>
                                                    </div>
                                                    <div className="col text-right">
                                                        <button
                                                            title="Add Bank"
                                                            type="button"
                                                            className="btn btn-link"
                                                            onClick={(e) => this.swapAccount(true, e)}
                                                        >
                                                            <span>{this.props.t('transfer.anyIBAN')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <Field
                                                    name="toAccountId"
                                                    value={values.toAccountId}
                                                    options={toAccountList}
                                                    readOnly
                                                    onChange={(value) => {
                                                        setFieldValue('toAccountId', value !== null ? value : []);
                                                        this.initialState.toAccountId = value;
                                                        value !== null
                                                            ? this.setState({
                                                                  toAccountId: value.numberOriginal,
                                                              })
                                                            : this.setState({ toAccountId: '' });
                                                        value !== null
                                                            ? this.setState({
                                                                  toAccountAmount: value.balance,
                                                              })
                                                            : this.setState({ toAccountAmount: '' });
                                                        value !== null
                                                            ? this.setState({
                                                                  toAccountName: value.bankName,
                                                              })
                                                            : this.setState({ toAccountName: '' });
                                                    }}
                                                    isMulti={false}
                                                    isInvalid={false}
                                                    isClearable={true}
                                                    getOptionLabel={(opt) => opt.name}
                                                    getOptionValue={(opt) => opt.id}
                                                    placeholder={this.props.t('transfer.accountLabelSelect')}
                                                    component={ReactSelectBox}
                                                    className="custom-dropdown custom-dropdown-height mb-3"
                                                    styles={customDropdown}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6"></div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-6 mb-sm-4 mb-3">
                                                        <Field
                                                            name="Amount"
                                                            label={this.props.t('transfer.amount')}
                                                            maxLength={50}
                                                            component={ReactstrapInput}
                                                            placeholder="0.00"
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'Amount',
                                                                    this.formatNumber(e.target.value),
                                                                );
                                                            }}
                                                            value={values.Amount}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-sm-6 mb-sm-4 mb-3">
                                                        <Field
                                                            multiline
                                                            name="Description"
                                                            label={this.props.t('transfer.transferDescription')}
                                                            component={ReactstrapInput}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Group
                                            className="custom-check mb-4 custom-check-radius"
                                            controlId="formBasicCheckbox1"
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                label={
                                                    <span>
                                                        {this.props.t('transfer.iAgree')}{' '}
                                                        {loadStorage(Common.Keys.UserLanguage) === 'el' ? (
                                                            <a
                                                                className="d-inline-block link-blue"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href="https://infin8.eu/el/%CE%B3%CE%B5%CE%BD%CE%B9%CE%BA%CE%BF%CE%B9-%CE%BF%CF%81%CE%BF%CE%B9-%CE%BA%CE%B1%CE%B9-%CF%80%CF%81%CE%BF%CF%8B%CF%80%CE%BF%CE%B8%CE%B5%CF%83%CE%B5%CE%B9%CF%83-%CF%85%CF%80%CE%B7%CF%81%CE%B5%CF%83/"
                                                            >
                                                                {this.props.t('transfer.paymentIntiation')}{' '}
                                                                {this.props.t('transfer.termsConditions')}
                                                            </a>
                                                        ) : (
                                                            <a
                                                                className="d-inline-block link-blue"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href="https://infin8.eu/payment-initiation-terms-conditions/"
                                                            >
                                                                {this.props.t('transfer.paymentIntiation')}{' '}
                                                                {this.props.t('transfer.termsConditions')}
                                                            </a>
                                                        )}
                                                    </span>
                                                }
                                                onClick={this.conditionCheck}
                                            />
                                        </Form.Group>
                                        {this.state.tranfersPending <= 0 ? (
                                            <Form.Group
                                                className="custom-check mb-4 custom-check-radius"
                                                controlId="formBasicCheckbox2"
                                            >
                                                <Form.Check
                                                    type="checkbox"
                                                    label={this.props.t('transfer.acceptPaymentChargesViaStripe')}
                                                    onClick={this.paymentCheck}
                                                />
                                            </Form.Group>
                                        ) : null}
                                        {this.state.tranfersPending <= 0 ? (
                                            this.state.conditionCheck === true && this.state.paymentCheck === true ? (
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    title="Continue"
                                                    size="sm"
                                                    className="mr-2"
                                                >
                                                    <span>{this.props.t('transfer.continue')}</span>
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    title="Continue"
                                                    size="sm"
                                                    className="mr-2"
                                                    disabled
                                                >
                                                    <span>{this.props.t('transfer.continue')}</span>
                                                </Button>
                                            )
                                        ) : this.state.conditionCheck === true ? (
                                            <Button
                                                type="submit"
                                                color="primary"
                                                title="Continue"
                                                size="sm"
                                                className="mr-2"
                                            >
                                                <span>{this.props.t('transfer.continue')}</span>
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                color="primary"
                                                title="Continue"
                                                size="sm"
                                                className="mr-2"
                                                disabled
                                            >
                                                <span>{this.props.t('transfer.continue')}</span>
                                            </Button>
                                        )}
                                    </form>
                                );
                            }}
                        </Formik>
                    ) : (
                        // Tranfer from own account page end
                        // Transfer by Own Account start
                        <Formik
                            initialValues={this.initialState}
                            onSubmit={this.paymentDetailSubmit}
                            validationSchema={this.ValidationSchema}
                        >
                            {(props) => {
                                const {
                                    handleSubmit,
                                    values,
                                    setFieldValue,
                                    resetForm,
                                    submitForm,
                                    errors,
                                    touched,
                                } = props;

                                return (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-xl-6 mb-sm-4 mb-3">
                                                        <div className="bank-transfer-selection mb-3">
                                                            <div className="bank-transfer-single-selection">
                                                                <span className="selection-control-label">
                                                                    {this.props.t('transfer.sourceFrom')}
                                                                </span>
                                                                <Field
                                                                    name="fromAccountId"
                                                                    value={values.fromAccountId}
                                                                    options={fromAccountList}
                                                                    readOnly
                                                                    onChange={(value) => {
                                                                        setFieldValue(
                                                                            'fromAccountId',
                                                                            value !== null ? value : [],
                                                                        );
                                                                        // this.initialState.fromAccountId = value;
                                                                        value !== null
                                                                            ? this.setState({
                                                                                  fromAccountId: value.numberOriginal,
                                                                              })
                                                                            : this.setState({
                                                                                  fromAccountId: '',
                                                                              });
                                                                        value !== null
                                                                            ? this.setState({
                                                                                  fromAccountAmount: value.balance,
                                                                              })
                                                                            : this.setState({
                                                                                  fromAccountAmount: '',
                                                                              });
                                                                        value !== null
                                                                            ? this.setState({
                                                                                  fromAccountName: value.bankName,
                                                                              })
                                                                            : this.setState({
                                                                                  fromAccountName: '',
                                                                              });
                                                                    }}
                                                                    isMulti={false}
                                                                    isInvalid={false}
                                                                    className="custom-dropdown custom-dropdown-height mb-3"
                                                                    isClearable={true}
                                                                    getOptionLabel={(opt) => opt.name}
                                                                    getOptionValue={(opt) => opt.id}
                                                                    placeholder={this.props.t(
                                                                        'transfer.accountLabelSelect',
                                                                    )}
                                                                    component={ReactSelectBox}
                                                                    styles={customDropdown}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-xl-0 mb-3">
                                                            <div className="col">
                                                                <span className="selection-control-label">
                                                                    {this.props.t('transfer.sourceTo')}
                                                                </span>
                                                            </div>
                                                            <div className="col text-right">
                                                                <button
                                                                    title="swap Account"
                                                                    type="button"
                                                                    className="btn btn-link"
                                                                    onClick={(e) => this.swapAccount(false, e)}
                                                                >
                                                                    <span>{this.props.t('transfer.ownAccounts')}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="mb-sm-4 mb-3">
                                                            <Field
                                                                name="IBAN"
                                                                label={this.props.t('transfer.transferIBANPlaceholder')}
                                                                maxLength={50}
                                                                component={ReactstrapInput}
                                                            />
                                                            {/* {touched.IBAN && errors.IBAN && <div>{errors.IBAN}</div>} */}
                                                        </div>
                                                        <div className="mb-sm-4 mb-3">
                                                            <Field
                                                                name="recipientName"
                                                                label={this.props.t(
                                                                    'transfer.transferRecipientPlaceholder',
                                                                )}
                                                                maxLength={50}
                                                                component={ReactstrapInput}
                                                            />
                                                        </div>
                                                        <div className="mb-sm-4 mb-3">
                                                            <Field
                                                                name="Amount"
                                                                label={this.props.t('transfer.amount')}
                                                                maxLength={50}
                                                                component={ReactstrapInput}
                                                                placeholder="0.00"
                                                            />
                                                            {/* {touched.Amount && errors.Amount && <div>{errors.Amount}</div>} */}
                                                        </div>
                                                        <div className="mb-sm-4 mb-3">
                                                            <Field
                                                                name="Description"
                                                                label={this.props.t('transfer.transferDescription')}
                                                                maxLength={50}
                                                                component={ReactstrapInput}
                                                            />
                                                            {/* {touched.Description && errors.Description && <div>{errors.Description}</div>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Group
                                            className="custom-check mb-4 custom-check-radius"
                                            controlId="formBasicCheckbox1"
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                label={
                                                    <span>
                                                        {this.props.t('transfer.iAgree')}{' '}
                                                        {loadStorage(Common.Keys.UserLanguage) === 'el' ? (
                                                            <a
                                                                className="d-inline-block link-blue"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href="https://infin8.eu/el/%CE%B3%CE%B5%CE%BD%CE%B9%CE%BA%CE%BF%CE%B9-%CE%BF%CF%81%CE%BF%CE%B9-%CE%BA%CE%B1%CE%B9-%CF%80%CF%81%CE%BF%CF%8B%CF%80%CE%BF%CE%B8%CE%B5%CF%83%CE%B5%CE%B9%CF%83-%CF%85%CF%80%CE%B7%CF%81%CE%B5%CF%83/"
                                                            >
                                                                {this.props.t('transfer.paymentIntiation')}{' '}
                                                                {this.props.t('transfer.termsConditions')}
                                                            </a>
                                                        ) : (
                                                            <a
                                                                className="d-inline-block link-blue"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href="https://infin8.eu/payment-initiation-terms-conditions/"
                                                            >
                                                                {this.props.t('transfer.paymentIntiation')}{' '}
                                                                {this.props.t('transfer.termsConditions')}
                                                            </a>
                                                        )}
                                                    </span>
                                                }
                                                onClick={this.conditionCheck}
                                            />
                                        </Form.Group>
                                        {this.state.tranfersPending <= 0 ? (
                                            <Form.Group
                                                className="custom-check mb-4 custom-check-radius"
                                                controlId="formBasicCheckbox2"
                                            >
                                                <Form.Check
                                                    type="checkbox"
                                                    label={this.props.t('transfer.acceptPaymentChargesViaStripe')}
                                                    onClick={this.paymentCheck}
                                                />
                                            </Form.Group>
                                        ) : null}
                                        {this.state.tranfersPending <= 0 ? (
                                            this.state.conditionCheck === true && this.state.paymentCheck === true ? (
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    title="Continue"
                                                    size="sm"
                                                    className="mr-2"
                                                >
                                                    <span>{this.props.t('transfer.continue')}</span>
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    title="Continue"
                                                    size="sm"
                                                    className="mr-2"
                                                    disabled
                                                >
                                                    <span>{this.props.t('transfer.continue')}</span>
                                                </Button>
                                            )
                                        ) : this.state.conditionCheck === true ? (
                                            <Button
                                                type="submit"
                                                color="primary"
                                                title="Continue"
                                                size="sm"
                                                className="mr-2"
                                            >
                                                <span>{this.props.t('transfer.continue')}</span>
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                color="primary"
                                                title="Continue"
                                                size="sm"
                                                className="mr-2"
                                                disabled
                                            >
                                                <span>{this.props.t('transfer.continue')}</span>
                                            </Button>
                                        )}
                                    </form>
                                );
                            }}
                        </Formik>
                    )}
                </div>
            </>
        );
    }
}

export default withTranslation()(withAlert()(Transfer));
