//language: Spanish
export default {
    LoginTitle: 'Todo lo que necesitará para sus operaciones bancarias',
    language: 'es',
    login: {
        loginLanguage: 'idioma',
        welcomeMsg: 'Bienvenido a Infin8',
        title: 'Iniciar sesión',
        email: 'Correo electrónico o nombre de usuario',
        password: 'Contraseña',
        signupMsg: 'La primera vez aquí?',
        signup: 'Regístrate',
        forgotPassword: '¿Olvidó su contraseña?',
    },
    signup: {
        email: 'correo electrónico',
        username: 'nombre de usuario',
        fullName: 'Nombre completo',
        password: 'Contraseña',
        phoneNumber: 'Phone Number (including country code with + sign)',
        confirmpassword: 'Confirmar contraseña',
        register: 'Registrarse',
        emailOtp: 'Email OTP',
        phoneOtp: 'Phone OTP',
        backto: 'De regreso',
        registrationSuccessTitle: 'Su registro se ha completado exitosamente.',
    },
    signupstep2: {
        step2: 'Paso 2',
        mobileInstruction:
            'Ingrese su número de teléfono móvil con el código de país (por ejemplo, +306944646447) para recibir un código de verificación',
        next: 'Próximo',
    },
    notification: {
        notificationTitle: 'Notificações',
    },
    error: {
        validateDifferentAccount: 'Seleccione una cuenta diferente',
        validatedIban: 'Introduzca un número IBAN válido',
        Required: 'Necesaria',
        invalidEmail: 'Email inválido',
        invalidPhone: 'Incorrect mobile number format',
        emailExist: 'usuario con este correo electrónico ya existe.',
        passwordsDoesntMatch: 'Verificar contraseña',
        transactionNoData: 'No se encontraron transacciones',
        newPassError: 'Comprobar contraseña',
        newPasswordConfirmInvalid: 'Verificar la confirmación de la contraseña',
        passwordStrongRules:
            'La longitud de la contraseña debe tener entre 6 y 16 caracteres e incluir letras mayúsculas y minúsculas, un número y uno de estos caracteres especiales : !@#$%&-_)*(',
    },
    individual: 'Individual',
    business: 'Negocio',
    accept: 'Aceptar',
    show: 'Mostrar',
    hide: 'Esconder',
    save: 'Guardar',
    update: 'actualizar',
    select: 'Seleccionar',
    cancel: 'Cancelar',
    back: 'Atrás',
    DashboardTitle: 'Tablero',
    DashboardNoAccounts: 'No tiene cuentas. Conecte su banco y recopile estadísticas.',
    Expenses: 'Gastos',
    History: 'Historial',
    Bank: 'Banco',
    AddBank: 'Agregar banco',
    MyProfile: 'Mi perfil',
    Settings: 'Configuraciones',
    TermsAndCond: 'Términos y Condiciones',
    Logout: 'Cerrar sesión',
    TotalAmount: 'Cantidad total',
    VatNumber: 'Número de valor agregado',
    ConnectedBanks: 'Bancos conectados',
    bankPayments: 'Pagamentos',
    paymentMethods: 'Cartões',
    subscriptionTitle: 'Assinatura',
    News: 'Noticias',
    Dashboard: {
        Tabs: {
            iHave: 'Tengo',
            iOwe: 'Debo',
            iCanSpend: 'Puedo gastar',
        },
        balanceAcrossBanks: 'Equilibrio entre sus bancos',
        iHave_transactionalAccountsFilterTitle: 'Cuentas transaccionales',
        iHave_savingsAccountFilterTitle: 'Cuenta de ahorros',
        iHave_termDepositFilterTitle: 'Depósito a plazo',
        iOwe_creditcardsFilterTitle: 'Tarjetas de crédito',
        iOwe_overdraftsFilterTitle: 'Sobregiros',
        iCanSpend_noFeeSpendingFilterTitle: 'Gastos sin cargos',
        iCanSpend_feeSpendingFilterTitle: 'Gastos con cargos',
        iCanSpend_transferToSpendFilterTitle: 'Transferir para gastar',
        iHave_headerMessage: 'Saldos de cuentas',
        iOwe_headerMessage: 'Cantidades adeudadas',
        iCanSpend_headerMessage: 'Disponible para gastar',
    },
    Banks: {
        addBank: {
            reconnectWithPayments: 'Restabelecer a ligação com os pagamentos',
            connectAllowPayment: 'Permitir pagamentos',
            searchPlaceholder: 'Buscar',
            title: 'Infin8 - Bancos',
            pageTitle: 'Bancos',
            button: {
                back: 'atrás',
                connected: 'Conectar',
                disconnected: 'Desconectar',
                reconnected: 'Reconectar',
            },
            text: {
                connectNote:
                    'Nota importante: su banco le pedirá permiso para conectarse a Afterbanks LTD, que es nuestro proveedor de conectividad bancaria. Por favor aceptalo.',
                bankName: 'Nombre del banco',
                bankswift: 'Swift bancaria',
                countTransaction: 'Contar transacción',
                countAccount: 'Contar cuenta',
                paymentSupport: 'Pagamentos',
                bankLastSyncAt: 'Última sincronización de datos',
            },
            status: {
                connected: 'Conectado',
                disconnected: 'Desconectado',
                pending: 'Pendiente',
                reconnect: 'Reconectar',
            },
        },
    },
    Dates: {
        today: 'Hoy',
        yesterday: 'Ayer',
    },
    Transaction: {
        title: 'Infin8 - transacciones',
        last_transactions: 'Últimas transacciones',
        transaction_category: 'Categoría de transacción',
        description: 'Descripción',
        receipt: 'Recibo',
        selectTransactionCategory: 'Seleccionar categoría de transacción',
        categoryChangeSuccess: 'categoría cambiada con éxito',
        transferSaveDocument: 'Documento',
        transferSaveImage: 'Imagen',
        transferSaveReceiptAs: 'Guardar o enviar el recibo como documento o imagen.',
    },
    TransactionHistory: {
        title: 'Infin8 - Historial de transacciones',
        pageTitle: 'Historial de transacciones',
        button: {
            filter: 'Filtrar',
            clear: 'limpiar',
            exportToExcel: 'Exportar a Excel',
        },
        label: {
            dateRange: 'From/To date',
            from: 'Desde',
            to: 'A',
            category: 'Categoría',
            bank: 'Banco',
            cardOrAccount: 'Tarjeta o cuenta específica',
            income: 'Ingresos',
            expense: 'Trasferencias',
        },
    },
    Setting: {
        appSettingsLang: 'Lenguaje de aplicación',
        menuChangePass: 'Cambiar la contraseña',
        currentPassword: 'Contraseña actual',
        newPassword: 'Nueva contraseña',
        newPasswordConfirm: 'Confirmar nueva contraseña',
        passwordChangeSuccess: 'Su contraseña fue cambiada exitosamente',
    },
    Profile: {
        firstName: 'Nombre',
        lastName: 'Apellido',
        email: 'correo electrónico',
        dateOfBirth: 'Fecha de nacimiento',
        phone: 'Teléfono',
        sex: 'Sexo',
        postCode: 'Código postal',
        address: 'Habla a',
        company: 'Empresa',
        sexMale: 'Masculino',
        sexFemale: 'Feminino',
        profileSaved: 'Perfil guardado',
    },
    ExpensesPage: {
        title: 'Infin8 - Trasferencias',
        pageTitle: 'Trasferencias',
        button: {
            filter: 'Filtrar',
            clear: 'limpiar',
            Exportpdf: 'Exportar a pdf',
        },
        label: {
            dateRange: 'From/To date',
            from: 'Desde',
            to: 'A',
            category: 'Categoría',
            bank: 'Banco',
            income: 'Ingresos',
            expense: 'Trasferencias',
            all: 'Todos',
            totalAmount: 'Cantidad total',
            cashFlow: 'Flujo de efectivo',
        },
    },
    forgotPassword: {
        title: 'Olvidaste tu contraseña',
        pageTitle: 'Olvidaste tu contraseña',
        button: {
            continue: 'continuar',
            save: 'Guardar',
        },
        label: {
            email: 'Correo electrónico',
            password: 'Introduzca nueva contraseña',
            confirmpassword: 'Confirmar nueva contraseña',
            forgetPassword: 'Olvidaste tu contraseña',
            otp: 'OTP',
            provideOTP: 'Proporcionar OTP',
            createNewPassword: 'Crear nueva contraseña',
            otpExpires: 'OTP expira en',
        },
    },
    alertMsg: {
        userPhoneExist: 'el teléfono del usuario existe',
        userExist: 'el correo electrónico del usuario existe',
        wentWrong: 'Algo salió mal. Inténtalo de nuevo.',
        wrongOTP: '¡OTP incorrecta!',
        otpExpired: 'OTP expiró. Inténtalo de nuevo.',
        passwordChangeSuccess: 'Contraseña cambiada con éxito.',
        userNotFound: 'Usuario no encontrado.',
    },
    receipt: {
        description: 'Descripción',
        date: 'Fecha',
        amount: 'Cantidad',
        category: 'Categoría',
        bank: 'Banco',
        transDetails: 'Detalles de la transacción',
    },
    accountTypes: {
        checking: 'Cuenta corriente',
        debit: 'Cuenta de debito',
        card: 'Cuenta de tarjeta',
        investment: 'Cuenta de inversión',
        savings: 'Cuenta de ahorros',
    },
    transfer: {
        accountLabelSelect: 'Seleccionar cuenta',
        currentBalance: 'Saldo actual',
        transferScreen: 'Transferência',
        sourceFrom: 'De',
        sourceTo: 'Para',
        amount: 'Cantidad',
        transferDescription: 'Descrição',
        continue: 'continuar',
        acceptPaymentChargesViaStripe: 'Acepto cargos de Stripe',
        transferIBANPlaceholder: 'IBAN',
        transferRecipientPlaceholder: 'Nome do destinatário',
        transferConfirmationBottomText: 'Confirmar',
        paymentPrecheckInfo_PAYMENT_METHOD1: 'O seu cartão será debitado ',
        paymentPrecheckInfo_PAYMENT_METHOD2:
            'pela Infin8 como custo da transação. Poderão ser aplicados encargos bancários.',
        transactionRemaining: 'Transacciones restantes',
        AanyIBAN: 'Para o IBAN',
        ownAccounts: 'Para a sua conta',
        iAgree: 'Aceito os',
        paymentIntiation: 'Inicio de pago',
        termsConditions: 'Términos y Condiciones',
        ibanNumber: 'IBAN',
        note: 'Nota',
        transferText:
            'Por favor tenha em atenção que poderá demorar até 3 dias até que a transação seja confirmada e efetuada',
    },
    card: {
        paymentMethodsCreateTitle: 'Adicionar novo cartão',
        default: 'Predefinição',
        paymentMethodsDeleteButton: 'Apagar cartão',
        paymentMethodsCreateSubtitle: 'Dados dos cartões',
        cardHolder: 'Titular do cartão',
        cardNumber: 'Número do cartão',
        cardExpiry: 'Data de validade do cartão',
        cardCVC: 'CVC',
        paymentMethodsAddHint: 'As taxas de transação serão automaticamente debitadas do seu cartão',
        paymentMethodsSetAsDefault: 'Predefinir',
        acceptChargesViaStripe: 'Aceito cobranças via pacotes de assinaturas Stripe e taxas de transação',
        confirm: 'Confirmar',
        paymentMethodsTitle: 'Cartões',
        cardExpiresNote: 'Su tarjeta de pago ha caducado. Quite esta tarjeta y agregue una nueva.',
        expires: 'Expira',
    },
    subscription: {
        subscriptions: 'Assinatura',
        packageFree: 'Grátis',
        packageRuleTransferLimitText: 'Transações grátis por mês',
        packageRuleTransferAmountText: 'Custo de transações adicionais',
        months: 'Meses',
        packageRuleHistoryUnlimited: 'Sem limites',
        packageRuleExcelExportText: 'Transferência para o Excel',
        packageRulePushNotificationsText: 'Notificações push online',
        packageRuleHistoryLimitText: 'Contexto da transação',
        subscriptionStatusCurrent: 'Ativo',
        subscribe: 'Registar-se',
        subscriptionStatusNext: 'Próximo período',
        nextStartsAt: 'Começa a partir de',
        standard: 'Standard',
        premium: 'Premium',
        availableTransaction: 'Transacciones libres restantes',
        subscribed: 'Registar-se',
    },

    month: {
        january: 'enero',
        february: 'febrero',
        march: 'marzo',
        april: 'abril',
        may: 'mayo',
        june: 'junio',
        july: 'julio',
        august: 'agosto',
        september: 'septiembre',
        october: 'octubre',
        november: 'noviembre',
        december: 'diciembre',
        jan: 'enero',
        feb: 'febrero',
        mar: 'marzo',
        apr: 'abril',
        jun: 'junio',
        jul: 'julio',
        aug: 'agosto',
        sep: 'septiembre',
        oct: 'octubre',
        nov: 'noviembre',
        dec: 'diciembre',
    },
};
