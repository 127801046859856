import React from 'react';
import OtpInput from 'react-otp-input';

const ReactOTPInput = (props) => {
    const { OtpValue, numInputs, ...rest } = props;
    return (
        <div className="form-group">
            <OtpInput
                className="otp-input-block"
                value={OtpValue}
                onChange={(value) => props.onChange(value)}
                numInputs={numInputs}
                separator={<span>-</span>}
                {...rest}
            />
        </div>
    );
};

export default ReactOTPInput;
