import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { withTranslation } from 'react-i18next';
import userImg from '../assets/images/user-default-img.jpg';
import dashboardIcon from '../assets/images/dashboard-icon.svg';
import expensesIcon from '../assets/images/expenses-icon.svg';
import historyIcon from '../assets/images/history-icon.svg';
import banksIcon from '../assets/images/banks-icon.svg';
import paymentIcon from '../assets/images/payment-icon.svg';
import logOutMenuIcon from '../assets/images/logout-menu-icon.svg';
import myProfileIcon from '../assets/images/profile-icon.svg';
import settingsIcon from '../assets/images/settings-icon.svg';
import tAndCIcon from '../assets/images/t-and-c-icon.svg';
import SubscribeIcon from '../assets/images/subscribe-icon.svg';
import NewsIcon from '../assets/images/news-icon.svg';
import CardsIcon from '../assets/images/cards-icon.svg';
import logoutIcon from '../assets/images/logout-icon.svg';
import LoginService from '../services/login/login-service';
import { UserContext } from '../context/user/user-context';
import { isBlank, isEmptyObject, getFullName, compareStrings } from '../utils/helpers';
import { loadStorage, removeStorage, saveStorage } from '../utils/localStroage';
import { Common } from '../utils/constants';

const sideBar = (props) => {
    const loginService: LoginService = new LoginService();
    const contextData = useContext(UserContext);
    const setIsAuth = contextData.authData[1];
    const [userData, setUserData] = contextData.userData;
    const setAllBanks = contextData.banksData[1];
    const setAllAccounts = contextData.accountsData[1];

    let avatarLoaded = false;
    //Default Data
    const pageData = {
        profileImg: userImg,
        loginUserName: '',
        userIndividualName: '',
    };

    if (userData != null) {
        if (!isBlank(userData.avatar)) {
            if (loadStorage(Common.Keys.ImageUpdated) !== undefined) {
                removeStorage(Common.Keys.ImageUpdated);
                avatarLoaded = true;
            }
            pageData.profileImg = userData.avatar;
        }
        if (!isEmptyObject(userData.name) && !isBlank(userData.name.first))
            pageData.loginUserName = pageData.userIndividualName = getFullName(userData.name);
        if (!isBlank(userData.businessName)) pageData.loginUserName = userData.businessName;
    }

    const moveToPage = (type) => {
        props.history.push(type);
    };

    const logoutHandler = () => {
        loginService.logout();
        setIsAuth(false);
        setUserData(null);
        setAllBanks([]);
        setAllAccounts(null);
        props.history.push('/login');
    };

    const hideMenu = () => {
        document.body.classList.remove('menu-open');
    };

    return (
        <>
            {/* Start Sidebar */}
            <aside className="sidebar">
                {/* Start User Detail */}
                <div className="sidebar-top-part">
                    <img
                        src={avatarLoaded ? `${pageData.profileImg}?${new Date().getTime()}` : `${pageData.profileImg}`}
                        key={pageData.profileImg}
                        className="user-img"
                        title={pageData.loginUserName}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = userImg;
                        }}
                    />

                    <div className="d-flex justify-content-center">
                        <Dropdown className="user-dropdown">
                            <Dropdown.Toggle variant="link">{pageData.loginUserName}</Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => moveToPage('/profile')} title={props.t('MyProfile')}>
                                    <i>
                                        <img src={myProfileIcon} alt={props.t('MyProfile')} />
                                    </i>
                                    {props.t('MyProfile')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => moveToPage('/setting')} title={props.t('Settings')}>
                                    <i>
                                        <img src={settingsIcon} alt={props.t('Settings')} />
                                    </i>
                                    {props.t('Settings')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => moveToPage('/terms')} title={props.t('TermsAndCond')}>
                                    <i>
                                        <img src={tAndCIcon} alt={props.t('TermsAndCond')} />
                                    </i>
                                    {props.t('TermsAndCond')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {compareStrings('BUSINESS', userData.type) ? (
                        <p className="company-name text-center pb-0 text-truncate">{pageData.userIndividualName}</p>
                    ) : null}
                </div>
                {/* End User Detail */}

                {/* Start MainNavigation */}
                <Nav className="main-navigation">
                    <Nav.Item>
                        <NavLink
                            to={{
                                pathname: '/',
                                state: { fromSideBar: true },
                            }}
                            exact
                            title={props.t('DashboardTitle')}
                        >
                            <i>
                                <img src={dashboardIcon} alt={props.t('DashboardTitle')} />
                            </i>{' '}
                            {props.t('DashboardTitle')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink
                            to={{
                                pathname: '/expenses',
                                state: { fromSideBar: true },
                            }}
                            exact
                            title={props.t('Expenses')}
                        >
                            <i>
                                <img src={expensesIcon} alt={props.t('Expenses')} />
                            </i>{' '}
                            {props.t('Expenses')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink
                            to={{
                                pathname: '/history',
                                state: { fromSideBar: true },
                            }}
                            exact
                            title={props.t('History')}
                        >
                            <i>
                                <img src={historyIcon} alt={props.t('History')} />
                            </i>{' '}
                            {props.t('History')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink
                            to={{
                                pathname: '/addbank',
                                state: { fromSideBar: true },
                            }}
                            exact
                            title={props.t('Bank')}
                        >
                            <i>
                                <img src={banksIcon} alt={props.t('Bank')} />
                            </i>{' '}
                            {props.t('Bank')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink
                            to={{
                                pathname: '/transfer',
                                state: { fromSideBar: true },
                            }}
                            exact
                            title="Payment"
                        >
                            <i>
                                <img src={paymentIcon} alt="Payment" />
                            </i>{' '}
                            {props.t('bankPayments')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink
                            to={{
                                pathname: '/cards',
                                state: { fromSideBar: true },
                            }}
                            exact
                            title="Cards"
                        >
                            <i>
                                <img src={CardsIcon} alt="Cards" />
                            </i>{' '}
                            {props.t('paymentMethods')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink
                            to={{
                                pathname: '/subscribe',
                                state: { fromSideBar: true },
                            }}
                            exact
                            title="Subscribe"
                        >
                            <i>
                                <img src={SubscribeIcon} alt="Subscribe" />
                            </i>{' '}
                            {props.t('subscriptionTitle')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink
                            to={{
                                pathname: '/news',
                                state: { fromSideBar: true },
                            }}
                            exact
                            title="News"
                        >
                            <i>
                                <img src={NewsIcon} alt="News" />
                            </i>{' '}
                            {props.t('News')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink
                            onClick={logoutHandler}
                            to={{ pathname: '/login', state: { fromSideBar: true } }}
                            exact
                            title={props.t('Logout')}
                        >
                            <i>
                                <img src={logOutMenuIcon} alt={props.t('Logout')} />
                            </i>{' '}
                            {props.t('Logout')}
                        </NavLink>
                    </Nav.Item>
                </Nav>
                {/* End MainNavigation */}
            </aside>
            <div className="sidebar-overlay d-lg-none" onClick={hideMenu}></div>
            {/* End Sidebar */}
        </>
    );
};

export default withTranslation()(withRouter(sideBar));
