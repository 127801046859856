import React from 'react';
import infoIcon from '../../assets/images/info-icon.svg';
import successIcon from '../../assets/images/success-icon.svg';
import errorIcon from '../../assets/images/error-icon.svg';

const AlertTemplate = ({ style, options, message, close }) => {
    const classes = ['alert-box', 'alert-info'];

    switch (options.type) {
        case 'info':
            classes.push('alert-primary');
            break;

        case 'success':
            classes.push('alert-success');
            break;

        case 'error':
            classes.push('alert-danger');
            break;

        default:
            break;
    }

    return (
        <div className={classes.join(' ')} style={style}>
            {options.type === 'info' && (
                <i>
                    <img src={infoIcon} alt="Info" />
                </i>
            )}
            {options.type === 'success' && (
                <i>
                    <img src={successIcon} alt="Success" />
                </i>
            )}
            {options.type === 'error' && (
                <i>
                    <img src={errorIcon} alt="Errro" />
                </i>
            )}
            {message}
            <button onClick={close}>
                <span>x</span>
            </button>
        </div>
    );
};

export default AlertTemplate;
