import * as React from 'react';
import { Form } from 'react-bootstrap';

const ReactstrapSelectInput = ({ field, form: { touched, errors }, disabled = false, ...props }) => {
    const error = errors[field.name];
    const touch = touched[field.name];
    return (
        <Form.Group controlId={props.inputprops.id}>
            {props.label && <Form.Label className={'label-color'}>{props.label}</Form.Label>}
            <Form.Control
                as="select"
                {...field}
                {...props}
                type="select"
                disabled={props.inputprops.disabled}
                isInvalid={Boolean(touched[field.name] && errors[field.name])}
                placeholder="Test"
                className="custom-select"
            >
                <option value="">{props.inputprops.defaultOption}</option>
                {props.inputprops.options.map((option, index) => {
                    if (option.name)
                        return (
                            <option value={option.id} key={index}>
                                {option.name}
                            </option>
                        );
                    return (
                        <option value={option} key={index}>
                            {option}
                        </option>
                    );
                })}
            </Form.Control>
            {touch && error && error !== 'required' && (
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default ReactstrapSelectInput;
