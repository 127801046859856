import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Field, isEmptyArray, Formik } from 'formik';
import { RouteProps } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import VisaIcon from '../../assets/images/visa-logo.png';
import MasterCardIcon from '../../assets/images/mastercard-logo.png';
import StripeIcon from '../../assets/images/stripe-logo.png';
import PaypalIcon from '../../assets/images/paypal-logo.png';
import NoticeIcon from '../../assets/images/notice-icon.svg';
import CardPaymentService from '../../services/payment/Payment-service';
import { ReactSwitch } from '../../components/reactstrap-formik';
import { ICardPreviewState } from '../../types/stateTypes';
import { ICardProps } from '../../types/propTypes';
import { withAlert } from 'react-alert';

class CardPreview extends React.Component<RouteProps & WithTranslation> {
    goBackHandler = () => this.props.history.goBack();
    newDate = new Date();
    month = this.newDate.getMonth() + 1;
    year = this.newDate.getFullYear();
    state: ICardPreviewState;
    cardPaymentService: CardPaymentService;
    constructor(props: ICardProps & WithTranslation) {
        super(props);
        this.state = {
            default: false,
        };
        this.cardPaymentService = new CardPaymentService();
    }
    componentDidMount = async () => {
        const data = this.props.history.location.state.data;
        this.setState({ default: data.default });
    };
    getCardIcon = (brand) => {
        if (brand === 'notification') return NoticeIcon;
        if (brand === 'mastercard') return MasterCardIcon;
        if (brand === 'visa') return VisaIcon;
        if (brand === 'paypal') return PaypalIcon;
        if (brand === 'stripe') return StripeIcon;

        return brand;
    };
    defaultCard = () => {
        const data = this.props.history.location.state.data;
        this.cardPaymentService
            .setDefaultCard(data._id)
            .then((res) => {
                //console.log(res);
            })
            .catch((err) => {
                this.props.alert.error(err.toString());
            });
    };
    deleteCard = () => {
        const data = this.props.history.location.state.data;
        this.cardPaymentService
            .removeCard(data._id)
            .then((res) => {
                this.props.history.push('/cards');
            })
            .catch((err) => {
                this.props.alert.error(err.toString());
            });
    };
    render() {
        const { state } = this.props.history.location;
        return (
            <>
                <Helmet>
                    <title>{this.props.t('card.paymentMethodsTitle')}</title>
                </Helmet>
                {/* Back Button */}
                <div className="back-btn-outer">
                    <a className="back-btn" onClick={this.goBackHandler} title={this.props.t('back')}>
                        <i></i> {this.props.t('back')}
                    </a>
                </div>
                {/* Page Content */}
                <div className="page-content">
                    {/* Page Content Heading */}
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <h4 className="pb-0 semi-bold blue-text mb-sm-4 mb-3 pr-3">
                            {this.props.t('card.paymentMethodsCreateSubtitle')}
                        </h4>
                        {state.data.default === true ? (
                            <Button
                                className="btn btn-danger mb-4"
                                type="button"
                                color="danger"
                                size="lg"
                                title="Delete Card"
                                disabled
                            >
                                <span>{this.props.t('card.paymentMethodsDeleteButton')}</span>
                            </Button>
                        ) : (
                            <Button
                                className="btn btn-danger mb-4"
                                type="button"
                                color="danger"
                                size="lg"
                                title="Delete Card"
                                onClick={this.deleteCard}
                            >
                                <span>{this.props.t('card.paymentMethodsDeleteButton')}</span>
                            </Button>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="preview-card mr-3">
                                <img
                                    className="card-icon"
                                    src={this.getCardIcon(state.data.brand)}
                                    alt={state.data.brand.toUpperCase()}
                                />
                                <p className="card-number">
                                    {this.props.brand} **** **** **** {state.data.last4}
                                </p>
                                <p className="card-name">{state.data.cardholder}</p>
                                <span
                                    className={
                                        state.data.expMonth < this.month && state.data.expYear < this.year
                                            ? 'card-expiry errorText'
                                            : 'card-expiry'
                                    }
                                >
                                    {this.props.t('card.expires')} {state.data.expMonth}/
                                    {state.data.expYear.toString().slice(-2)}
                                </span>
                            </div>
                            {state.data.expMonth < this.month && state.data.expYear < this.year ? (
                                <div className="filled-alert-box danger mr-3 mt-4">
                                    <img className="alert-icon" src={NoticeIcon} alt="Notice Icon" />
                                    <span className="alert-message">{this.props.t('card.cardExpiresNote')}</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <Formik initialValues={null} onSubmit={null}>
                        {(props) => {
                            const { handleSubmit, values, setFieldValue, resetForm, submitForm } = props;

                            return (
                                <Field
                                    type="switch"
                                    id="default"
                                    label={this.props.t('card.default')}
                                    className="mt-3 mb-4"
                                    checked={this.state.default}
                                    onChange={(event) => {
                                        this.setState({ default: event.target.checked });
                                        this.defaultCard();
                                    }}
                                    component={ReactSwitch}
                                />
                            );
                        }}
                    </Formik>
                </div>
            </>
        );
    }
}

export default withTranslation()(withAlert()(CardPreview));
