import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import MainLayout from '../layout/MainLayout';
import Spinner from '../layout/Spinner';
import FallbackSpinner from '../layout/FallbackSpinner';
import { UserContext } from '../context/user/user-context';
import Transfer from '../pages/Transfer/Transfer';
import CardPreview from '../pages/Cards/CardPreview';
import AddNewCard from '../pages/Cards/AddNewCard';
import Success from '../pages/Success/Success';

const Transfers = React.lazy(() => import('../pages/Transfer/TransferConfirm'));
const LoginPage = React.lazy(() => import('../pages/login/LoginPage'));
const SignupPage = React.lazy(() => import('../pages/Signup/SignupPage'));
const ProfilePage = React.lazy(() => import('../pages/Profile/profile'));
const Dashboard = React.lazy(() => import('../pages/Dashboard/Dashboard'));
const AddBank = React.lazy(() => import('../pages/AddBank/AddBank'));
const Setting = React.lazy(() => import('../pages/Setting/Setting'));
const Notifications = React.lazy(() => import('../pages/Notifications/Notifications'));
const Expenses = React.lazy(() => import('../pages/Expenses/Expenses'));
const History = React.lazy(() => import('../pages/History/History'));
const Terms = React.lazy(() => import('../pages/Terms/Terms'));
const News = React.lazy(() => import('../pages/News/News'));
const Transaction = React.lazy(() => import('../pages/Transaction/Transaction'));
const Cards = React.lazy(() => import('../pages/Cards/Cards'));
const Subscribe = React.lazy(() => import('../pages/Subscribe/Subscribe'));
const Fp_Step_1 = React.lazy(() => import('../pages/ForgetPassword/ForgetPasswordPage'));

const Routes = () => {
    const context = useContext(UserContext);
    return (
        <React.Suspense fallback={<FallbackSpinner />}>
            <Switch>
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/signup" component={SignupPage} />
                <Route exact path="/forgotPassword" component={Fp_Step_1} />
                {context.authData[0] ? (
                    <MainLayout>
                        <AuthRoute exact path="/" component={Dashboard}></AuthRoute>
                        <AuthRoute exact path="/profile" component={ProfilePage}></AuthRoute>
                        <AuthRoute exact path="/addbank" component={AddBank}></AuthRoute>
                        <AuthRoute exact path="/setting" component={Setting}></AuthRoute>
                        <AuthRoute exact path="/notifications" component={Notifications}></AuthRoute>
                        <AuthRoute exact path="/expenses" component={Expenses}></AuthRoute>
                        <AuthRoute exact path="/history" component={History}></AuthRoute>
                        <AuthRoute exact path="/terms" component={Terms}></AuthRoute>
                        <AuthRoute exact path="/transaction" component={Transaction}></AuthRoute>
                        <AuthRoute exact path="/transfer" component={Transfer}></AuthRoute>
                        <AuthRoute exact path="/cards" component={Cards}></AuthRoute>
                        <AuthRoute exact path="/subscribe" component={Subscribe}></AuthRoute>
                        <AuthRoute exact path="/cards/preview" component={CardPreview}></AuthRoute>
                        <AuthRoute exact path="/cards/add-new-card" component={AddNewCard}></AuthRoute>
                        <AuthRoute exact path="/success" component={Success}></AuthRoute>
                        <AuthRoute exact path="/transferConfirm" component={Transfers}></AuthRoute>
                        <AuthRoute exact path="/News" component={News}></AuthRoute>
                    </MainLayout>
                ) : null}
                <Redirect to="/login" />
            </Switch>
        </React.Suspense>
    );
};

export default Routes;
