import React, { useRef } from 'react';
import { isBlank } from '../../utils/helpers';
import moment from 'moment';
import { Common } from '../../utils/constants';
import { loadStorage } from '../../utils/localStroage';
import _ from 'lodash';

const yearMonthForm = ({ date, localeUtils, onChange, maxYear }) => {
    const monthRef = useRef();
    const yearRef = useRef();
    const months = moment.localeData(loadStorage(Common.Keys.UserLanguage)).months();

    const currentYear = new Date().getFullYear();
    const fromMonth = new Date(isBlank(maxYear) ? currentYear : maxYear, 0);
    const toMonth = new Date(currentYear - 70, 11);

    const years = [];
    for (let i = fromMonth.getFullYear(); i >= toMonth.getFullYear(); i -= 1) {
        years.push(i);
    }

    const handleChange = (e) => {
        const year = _.get(yearRef, 'current.value', '');
        const month = _.get(monthRef, 'current.value', '');
        onChange(new Date(year, month));
    };

    return (
        <div className="DayPicker-Caption">
            <select
                className="custom-select font-15"
                style={{ width: '53%' }}
                ref={monthRef}
                name="month"
                onChange={handleChange}
                value={date.getMonth()}
            >
                {months.map((month, i) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            <select
                className="custom-select font-15"
                style={{ width: '29%' }}
                ref={yearRef}
                name="year"
                onChange={handleChange}
                value={date.getFullYear()}
            >
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default yearMonthForm;
