import React from 'react';
import { Form } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Common } from '../../utils/constants';
import YearMonthForm from './YearMonthForm';
import { isFirefox } from 'react-device-detect';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import { loadStorage } from '../../utils/localStroage';

// Make sure moment.js has the required locale data
import 'moment/locale/el';
import 'moment/locale/es';
import 'moment/locale/pt';
import { isBlank } from '../../utils/helpers';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear + 1, 0);
const toMonth = new Date(currentYear - 10, 11);

class ReactDayPickerInput extends React.Component {
    dateRef = React.createRef();

    constructor(props) {
        super(props);
        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
        this.state = {
            month: fromMonth,
            monthTo: toMonth,
        };
    }

    handleYearMonthChange = (month) => {
        this.setState({ month });
    };

    handleYearMonthChangeTo = (month) => {
        this.setState({ monthTo: month });
    };

    updateInputFromRef = (ref) => {
        if (ref && isFirefox) ref.input.focus = function () {};
    };

    updateInputToRef = (ref) => {
        if (ref && isFirefox) ref.input.focus = function () {};
    };

    render() {
        return (
            <>
                <div className="InputFromTo">
                    <DayPickerInput
                        ref={this.updateInputFromRef}
                        format={Common.DateFormat}
                        value={this.props.fromValue}
                        placeholder={isBlank(this.props.fromPlaceholder) ? 'FROM' : this.props.fromPlaceholder}
                        // format="LL"
                        // formatDate={formatDate}
                        // parseDate={parseDate}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            locale: loadStorage(Common.Keys.UserLanguage),
                            localeUtils: MomentLocaleUtils,
                            ...this.props.fromDayPickerProps,
                            // toMonth: this.props.toValue,
                            onDayClick: () => {
                                if (this.props.allowSubmit) {
                                    this.props.generalDayClick();
                                }
                            },
                            month: this.state.month,
                            captionElement: ({ date, localeUtils }) => (
                                <YearMonthForm
                                    date={date}
                                    localeUtils={MomentLocaleUtils}
                                    onChange={this.handleYearMonthChange}
                                />
                            ),
                        }}
                        onDayChange={(from) => this.props.fromDayChange(from)}
                    />{' '}
                    <span className="dash">-</span> {/* <span className="InputFromTo-to"> */}
                    <DayPickerInput
                        ref={this.updateInputToRef}
                        format={Common.DateFormat}
                        value={this.props.toValue}
                        placeholder={isBlank(this.props.toPlaceholder) ? 'To' : this.props.toPlaceholder}
                        // format="LL"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            locale: loadStorage(Common.Keys.UserLanguage),
                            localeUtils: MomentLocaleUtils,
                            ...this.props.todayPickerProps,
                            onDayClick: () => {
                                if (this.props.allowSubmit) {
                                    this.props.generalDayClick();
                                }
                            },
                            month: this.state.monthTo,
                            // numberOfMonths: this.state.monthTo.getMonth() == 12 ? 1 : 2,
                            captionElement: ({ date, localeUtils }) => (
                                <YearMonthForm
                                    date={date}
                                    localeUtils={MomentLocaleUtils}
                                    onChange={this.handleYearMonthChangeTo}
                                    maxYear={new Date().getFullYear() + 1}
                                />
                            ),
                        }}
                        onDayChange={(value) => this.props.toDayChange(value)}
                    />
                    {/* </span> */}
                </div>
            </>
        );
    }
}

export default ReactDayPickerInput;
