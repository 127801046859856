import i18next from 'i18next';
import InitService from '../../apis/init-service';
import BaseService from '../../apis/base-service';
import { loadStorage, saveStorage, removeStorage } from '../../utils/localStroage';
import { AuthKeys, Common } from '../../utils/constants';
import { isBlank, isEmptyObject, getCurrentTimeToCompare, trimData } from '../../utils/helpers';
import { ILoginFormField, ISignUpFormField } from '../../models/Login/Login';
import StandardResponse from '../../models/standard-response';
import UserService from '../user/user-service';
import { IUserProfileUpdate, IUserProfile } from '../../models/user/user';
import { IForgetPasswordStartOtp, IOTPdetails } from '../../models/Login/Login';

export default class LoginService {
    setInitToken = async () => {
        let tokenExpireAt: any = loadStorage(AuthKeys.InitRefreshTokenExpireAt) || '';
        const initRefreshToken: any = loadStorage(AuthKeys.InitRefreshToken) || '';
        const initToken: any = loadStorage(AuthKeys.InitToken) || '';
        let response: any = null;
        let error: any = null;

        if (!isBlank(tokenExpireAt)) tokenExpireAt = new Date(tokenExpireAt);

        try {
            if (
                tokenExpireAt instanceof Date &&
                getCurrentTimeToCompare() >= tokenExpireAt &&
                !isBlank(initRefreshToken)
            ) {
                response = await InitService.post('token/access/new', {
                    refreshToken: initRefreshToken,
                }).catch((err) => {
                    error = err;
                });

                if (!isEmptyObject(response) && error === null) {
                    const expireAt = new Date(new Date().getTime() + +response.data.data.expiresIn * 1000);

                    saveStorage(AuthKeys.InitRefreshTokenExpireAt, expireAt);
                    saveStorage(AuthKeys.InitToken, response.data.data.accessToken);
                    saveStorage(AuthKeys.InitRefreshToken, response.data.data.refreshToken);

                    return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
                } else {
                    removeStorage(AuthKeys.InitRefreshTokenExpireAt);
                    removeStorage(AuthKeys.InitToken);
                    removeStorage(AuthKeys.InitRefreshToken);
                }

                return null;
            }

            if (isBlank(initToken)) {
                response = await InitService.post('session/anon/init').catch((err) => {
                    error = err;
                });

                if (!isEmptyObject(response) && error === null) {
                    const expireAt = new Date(new Date().getTime() + +response.data.data.tokenInfo.expiresIn * 1000);

                    saveStorage(AuthKeys.InitRefreshTokenExpireAt, expireAt);
                    saveStorage(AuthKeys.InitToken, response.data.data.tokenInfo.accessToken);
                    saveStorage(AuthKeys.InitRefreshToken, response.data.data.tokenInfo.refreshToken);

                    return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
                } else {
                    removeStorage(AuthKeys.InitRefreshTokenExpireAt);
                    removeStorage(AuthKeys.InitToken);
                    removeStorage(AuthKeys.InitRefreshToken);
                }
            }

            return null;
        } catch (e) {
            console.error('Error at setInitToken', e);
            throw new Error(e);
        }
    };

    userExists = async (email: string) => {
        let response: any = null;
        let error: any = null;

        response = await InitService.post('user/email-exists', {
            email: trimData(email),
        }).catch((err) => {
            error = err;
        });

        if (!isEmptyObject(response) && error === null) {
            return true;
        } else {
            return false;
        }
    };

    phoneVerification = async (phone: string) => {
        let response: any = null;
        let error: any = null;
        response = await InitService.post('user/registration/verify-phone', {
            phone: phone,
        }).catch((err) => {
            error = err;
        });

        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    emailVerification = async (email: string) => {
        let response: any = null;
        let error: any = null;

        response = await InitService.post('user/registration/verify-email', {
            email: trimData(email),
        }).catch((err) => {
            error = err;
        });
        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    login = async (data: ILoginFormField) => {
        let response: any = null;
        let error: any = null;
        data = trimData(data);

        response = await InitService.post('login/user/authenticate/internal', {
            login: data.Email,
            password: data.Password,
        }).catch((err) => {
            error = err;
        });

        if (!isEmptyObject(response) && error === null) {
            saveStorage(AuthKeys.AuthToken, response.data.data.accessToken);
            saveStorage(AuthKeys.AuthRefreshToken, response.data.data.refreshToken);
            saveStorage(AuthKeys.AuthTokenExpireAt, response.data.data.accessTokenExpireAt);
            saveStorage(AuthKeys.AuthRefreshTokenExpireAt, response.data.data.refreshTokenExpireAt);
        } else {
            if (error.response && error.response.data && error.response.data.length > 0) {
                const dataObj = error.response.data[0];

                if (dataObj.hasOwnProperty('message')) throw new Error(dataObj.message);
            }

            throw new Error(error);
        }

        const userService: UserService = new UserService();
        const userData = await userService.getUserDetails();

        return new StandardResponse<any>(response.status, userData.Data, response.data.message, null);
    };

    signup = async (
        Name: string,
        Email: string,
        phone: string,
        Password: string,
        Type: string,
        VATNumber: string,
        phoneProcessId: string,
        emailProcessId: string,
    ) => {
        let response: any = null;
        let error: any = null;
        const dataToSend = {
            phoneProcessId: phoneProcessId,
            emailProcessId: emailProcessId,
            fullName: Name,
            email: Email,
            password: Password,
            type: Type,
            vatNumber: VATNumber,
            phone: phone,
        };
        response = await InitService.post('user/registration/complete', dataToSend).catch((err) => {
            error = err;
        });
        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    initForgetPassword = async (email: string) => {
        try {
            let response: any = null;
            let error: any = null;

            response = await InitService.post('login/user/authenticate/internal', {
                email: trimData(email),
            }).catch((err) => {
                error = err;
            });

            if (!isEmptyObject(response) && error === null) {
                saveStorage(AuthKeys.ForgetPasswordId, response.data.data.otp.id);
                saveStorage(AuthKeys.ForgetPasswordIdExpireAt, response.data.data.otp.validTill);
            } else {
                throw new Error(error);
            }

            return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
        } catch (e) {
            throw new Error('Something went wrong');
        }
    };

    setForgetPassword = async (password: string) => {
        try {
            let response: any = null;
            let error: any = null;

            response = await InitService.post('login/user/authenticate/internal', {
                processId: loadStorage(AuthKeys.ForgetPasswordId) || '',
                password: password,
            }).catch((err) => {
                error = err;
            });

            if (error !== null) {
                throw new Error(error);
            }

            return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
        } catch (e) {
            throw new Error('Something went wrong');
        }
    };

    tryAutoLogin = async (isGetUserData?: boolean) => {
        const authToken: any = loadStorage(AuthKeys.AuthToken) || '';
        const authRefreshToken: any = loadStorage(AuthKeys.AuthRefreshToken) || '';
        let authTokenExpireAt: any = loadStorage(AuthKeys.AuthTokenExpireAt) || '';
        let authRefreshTokenExpireAt: any = loadStorage(AuthKeys.AuthRefreshTokenExpireAt) || '';
        let response: any = null;
        let error: any = null;
        const returnData = {
            isLoggedIn: false,
            userData: null,
        };

        try {
            if (isBlank(authToken) || isBlank(authRefreshToken)) {
                this.logout();
                return returnData;
            }

            if (!isBlank(authTokenExpireAt)) authTokenExpireAt = new Date(authTokenExpireAt);

            if (!isBlank(authRefreshTokenExpireAt)) authRefreshTokenExpireAt = new Date(authRefreshTokenExpireAt);

            if (authRefreshTokenExpireAt instanceof Date && getCurrentTimeToCompare() >= authRefreshTokenExpireAt) {
                this.logout();
                return returnData;
            }

            if (authTokenExpireAt instanceof Date && getCurrentTimeToCompare() >= authTokenExpireAt) {
                response = await BaseService.post('token/access/new', {
                    refreshToken: authRefreshToken,
                }).catch((err) => {
                    error = err;
                });

                if (!isEmptyObject(response) && error === null) {
                    const expireAt = new Date(new Date().getTime() + +response.data.data.expiresIn * 1000);

                    saveStorage(AuthKeys.AuthRefreshTokenExpireAt, expireAt);
                    saveStorage(AuthKeys.AuthTokenExpireAt, expireAt);
                    saveStorage(AuthKeys.AuthToken, response.data.data.accessToken);
                    saveStorage(AuthKeys.AuthRefreshToken, response.data.data.refreshToken);
                } else {
                    throw new Error(error);
                }
            }

            if (isGetUserData === true) {
                const userService: UserService = new UserService();
                response = await userService.getUserDetails();
                returnData.userData = response.Data;
            }

            return { ...returnData, isLoggedIn: true };
        } catch (e) {
            console.error('Error at tryAutoLogin', e);
            return false;
        }
    };

    setUserLanguage = () => {
        if (!loadStorage(Common.Keys.UserLanguage)) saveStorage(Common.Keys.UserLanguage, Common.FallbackLanguage);

        const selectedLanguage = loadStorage(Common.Keys.UserLanguage);
        i18next.changeLanguage(selectedLanguage);
    };

    logout = async () => {
        removeStorage(AuthKeys.AuthToken);
        removeStorage(AuthKeys.AuthRefreshToken);
        removeStorage(AuthKeys.AuthTokenExpireAt);
        removeStorage(AuthKeys.AuthRefreshTokenExpireAt);
    };

    getDocuments = async () => {
        let response: any = null;
        let error: any = null;

        response = await InitService.get('documents').catch((err) => {
            error = err;
        });

        if (error !== null) {
            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    forgetPasswordStart = async (email: string) => {
        let response: any = null;
        let error: any = null;

        response = await InitService.post('fp/start', {
            email: email,
        }).catch((err) => {
            error = err;
        });

        if (error !== null) {
            return new StandardResponse<IForgetPasswordStartOtp>(
                error.response.status,
                error.response.data || null,
                null,
                error,
            );
        }

        return new StandardResponse<IForgetPasswordStartOtp>(
            response.status,
            response.data.data,
            response.data.message,
            null,
        );
    };

    getOTPdetails = async (id: string) => {
        let response: any = null;
        let error: any = null;

        response = await InitService.get('/otp/' + id + '/info').catch((err) => {
            error = err;
        });

        if (error !== null) {
            throw new StandardResponse<any>(error.response.status, error.response.data || null, null, error);
        }
        return new StandardResponse<IOTPdetails>(response.status, response.data, response.data.message, null);
    };

    sendOTP = async (id: string, code: string) => {
        try {
            let response: any = null;
            let error: any = null;

            response = await InitService.post('otp/' + id, {
                code: code,
            }).catch((err) => {
                error = err;
            });

            if (error !== null) {
                return new StandardResponse<any>(error.response.status, error.response.data || null, null, error);
            }

            return new StandardResponse<any>(response.status, response.data, response.data.message, null);
        } catch (e) {
            throw new Error('Something went wrong');
        }
    };

    setPassword = async (pId: string, pass: string) => {
        try {
            let response: any = null;
            let error: any = null;

            response = await InitService.post('fp/password', {
                processId: pId,
                password: pass,
            }).catch((err) => {
                error = err;
            });

            if (error !== null) {
                throw new StandardResponse<any>(error.response.status, error.response.data || null, null, error);
            }

            return new StandardResponse<any>(response.status, response.data, response.data.message, null);
        } catch (e) {
            throw new Error('Something went wrong');
        }
    };
}
