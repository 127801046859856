import React from 'react';

const FallbackSpinner: React.FunctionComponent<any> = (props) => (
    <div className="page-loader">
        <div className="loader-animation">
            <div></div>
            <div></div>
        </div>
    </div>
);

export default FallbackSpinner;
