import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Field, Formik } from 'formik';
import { RouteProps } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import CardPaymentService from '../../services/payment/Payment-service';
import { ICardAddState } from '../../types/stateTypes';
import { ReactstrapInput, ReactstrapRadio, ReactSwitch } from '../../components/reactstrap-formik';
import { ICardProps } from '../../types/propTypes';
import { ICardFields } from '../../models/Card/Card';
import { withAlert } from 'react-alert';
import * as Yup from 'yup';
import firebase from '../../firebase/firebase';

class AddNewCard extends React.Component<RouteProps & WithTranslation> {
    goBackHandler = () => this.props.history.goBack();
    cardPaymentService: CardPaymentService;
    analytics = firebase.analytics();
    state: ICardAddState;
    private initialState: ICardFields = {
        cardHolder: '',
        cardNumber: '',
        expiry: undefined,
        CVC: undefined,
        default: false,
    };
    constructor(props: ICardProps & WithTranslation) {
        super(props);
        this.state = {
            defaultValue: false,
            condition: false,
            cardNumber: null,
            expiry: null,
            card: null,
            defaultCard: false,
        };
        this.cardPaymentService = new CardPaymentService();
    }
    ValidationSchema = Yup.object().shape({
        cardHolder: Yup.string()
            .max(30, 'Name must be less then 30 characters')
            .required(this.props.t('error.Required')),
        CVC: Yup.string().max(3, 'Must be exactly 3 digits').required(this.props.t('error.Required')),
    });
    componentDidMount = async () => {
        console.log(this.props.history.location);
        this.cardPaymentService
            .getCards()
            .then((res) => {
                res.Data.map((card) => (card.default === true ? this.setState({ defaultValue: false }) : null));
                this.setState({ card: res.Data });
                if (res.Data.length == 0) {
                    this.setState({ defaultCard: true });
                }
            })
            .catch((err) => {
                this.props.alert.error(err.toString());
            });
    };
    handleCardNumber = (e) => {
        let formattedText = e.target.value.split(' ').join('');
        if (formattedText.length > 0) {
            formattedText = formattedText.match(new RegExp('.{1,4}', 'g')).join(' ');
        }
        this.setState({ cardNumber: formattedText });
        this.initialState.cardNumber = formattedText;
        return formattedText;
    };
    handleExpiry = (e) => {
        let formattedText = e.target.value.split('/').join('');
        if (formattedText.length > 0) {
            formattedText = formattedText.match(new RegExp('.{1,2}', 'g')).join('/');
        }
        this.setState({ expiry: formattedText });
        this.initialState.expiry = formattedText;
        return formattedText;
    };

    handleSubmit = (values) => {
        const year = this.state.expiry.toString().slice(-2);
        const month = this.state.expiry.substr(0, 2);
        this.cardPaymentService
            .addCard(this.state.cardNumber, month, year, values.CVC, values.cardHolder, this.state.defaultValue)
            .then((res) => {
                if (res !== null) {
                    if (this.state.card.length == 0) {
                        firebase.analytics().logEvent('Add first card for Stripe payments');
                    }
                    if (this.props.history.location.state != undefined) {
                        this.props.history.goBack();
                    } else {
                        this.props.history.push('/cards');
                    }
                }
            })
            .catch((err) => {
                firebase.analytics().logEvent('Failed Addition of Card');
                this.props.alert.error(err.toString());
            });
    };
    defaultChange = () => {
        this.initialState.default = !this.state.defaultValue;
        this.setState({ defaultValue: !this.state.defaultValue });
    };
    conditionConfirm = () => {
        this.setState({ condition: !this.state.condition });
    };
    resetForm = () => {
        this.initialState.cardNumber = '';
        this.initialState.expiry = undefined;
    };
    render() {
        const { defaultValue } = this.state;
        return (
            <>
                <Helmet>
                    <title>{this.props.t('card.paymentMethodsCreateTitle')}</title>
                </Helmet>

                {/* Back Button */}
                <div className="back-btn-outer">
                    <a className="back-btn" onClick={this.goBackHandler} title={this.props.t('back')}>
                        <i></i> {this.props.t('back')}
                    </a>
                </div>
                {/* Page Content */}
                <div className="page-content">
                    {/* Page Content Heading */}
                    <div className="mb-5">
                        <h4 className="pb-0 semi-bold blue-text">{this.props.t('card.paymentMethodsCreateTitle')}</h4>
                    </div>
                    <Formik
                        initialValues={this.initialState}
                        onSubmit={this.handleSubmit}
                        validationSchema={this.ValidationSchema}
                    >
                        {(props) => {
                            const {
                                handleSubmit,
                                values,
                                setFieldValue,
                                resetForm,
                                submitForm,
                                errors,
                                touched,
                            } = props;

                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-6">
                                            <Field
                                                type="first"
                                                name="cardHolder"
                                                id="cardHolder"
                                                label={this.props.t('card.cardHolder')}
                                                maxLength={50}
                                                component={ReactstrapInput}
                                            />
                                        </div>
                                        <div className="col-xl-3 col-sm-6">
                                            <Field
                                                type="text"
                                                name="cardNumber"
                                                id="cardNumber"
                                                label={this.props.t('card.cardNumber')}
                                                maxLength={19}
                                                component={ReactstrapInput}
                                                placeholder="0000 0000 0000 0000"
                                                onChange={(e) => this.handleCardNumber(e)}
                                                value={this.initialState.cardNumber}
                                            />
                                        </div>
                                        <div className="col-xl-3 col-sm-6">
                                            <Field
                                                type="first"
                                                name="expiry"
                                                id="expiry"
                                                label={this.props.t('card.cardExpiry')}
                                                maxLength={5}
                                                component={ReactstrapInput}
                                                placeholder="MM/YY"
                                                onChange={(e) => this.handleExpiry(e)}
                                                value={this.initialState.expiry}
                                            />
                                        </div>
                                        <div className="col-xl-3 col-sm-6">
                                            <Field
                                                type="first"
                                                name="CVC"
                                                id="CVC"
                                                label={this.props.t('card.cardCVC')}
                                                maxLength={3}
                                                component={ReactstrapInput}
                                                placeholder="***"
                                            />
                                        </div>
                                    </div>
                                    <p className="note-statement fs-14 mb-2">
                                        {' '}
                                        <b className="semi-bold">{this.props.t('transfer.note')}:</b>{' '}
                                        {this.props.t('card.paymentMethodsAddHint')}
                                    </p>
                                    {this.state.defaultCard == true ? (
                                        <Form.Group
                                            className="custom-check mb-md-4 mb-3 square-check"
                                            controlId="formBasicCheckbox1"
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                name="default"
                                                label={this.props.t('card.paymentMethodsSetAsDefault')}
                                                checked={this.state.defaultCard}
                                                disabled
                                            />
                                        </Form.Group>
                                    ) : (
                                        <Form.Group
                                            className="custom-check mb-md-4 mb-3 square-check"
                                            controlId="formBasicCheckbox2"
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                name="default"
                                                label={this.props.t('card.paymentMethodsSetAsDefault')}
                                                checked={defaultValue}
                                                onClick={() => this.defaultChange()}
                                            />
                                        </Form.Group>
                                    )}

                                    <Form.Group
                                        className="custom-check mb-md-4 mb-3 square-check"
                                        controlId="formBasicCheckbox3"
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            label={this.props.t('card.acceptChargesViaStripe')}
                                            checked={this.state.condition}
                                            onClick={this.conditionConfirm}
                                        />
                                    </Form.Group>

                                    <div className="pt-2">
                                        {this.state.condition === true ? (
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                title="Confirm"
                                                size="lg"
                                                style={{ minWidth: 120 }}
                                            >
                                                <span>{this.props.t('card.confirm')}</span>
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                title="Confirm"
                                                size="lg"
                                                style={{ minWidth: 120 }}
                                                disabled
                                            >
                                                <span>{this.props.t('card.confirm')}</span>
                                            </Button>
                                        )}
                                        <Button
                                            type="reset"
                                            variant="light"
                                            title="cancel"
                                            size="lg"
                                            className="ml-3"
                                            style={{ minWidth: 120 }}
                                            onClick={() => {
                                                resetForm();
                                                this.resetForm();
                                            }}
                                        >
                                            <span>{this.props.t('cancel')}</span>
                                        </Button>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </>
        );
    }
}

export default withTranslation()(withAlert()(AddNewCard));
