import BaseService from '../../apis/base-service';
import StandardResponse from '../../models/standard-response';

export default class CardPaymentService {
    getCards = async () => {
        let response: any = null;
        let error: any = null;
        response = await BaseService.get('cards').catch((err) => {
            error = err;
        });
        if (error !== null) {
            if (error.response && error.response.data && error.response.data.length > 0) {
                const dataObj = error.response.data[0];
                if (dataObj.hasOwnProperty('message')) {
                    throw new Error(dataObj.message);
                }
            }

            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data, response.data.message, null);
    };

    addCard = async (
        number: string,
        expMonth: number,
        expYear: number,
        cvc: number,
        cardholder: string,
        defaults: boolean,
    ) => {
        let response: any = null;
        let error: any = null;
        const cardNumber = number.replace(/ /g, '');
        response = await BaseService.post('cards', {
            number: cardNumber,
            expMonth: Number(expMonth),
            expYear: Number(20 + expYear),
            cvc: Number(cvc),
            cardholder: cardholder,
            default: defaults,
        }).catch((err) => {
            error = err;
        });
        if (error !== null) {
            if (error.response && error.response.data && error.response.data.length > 0) {
                const dataObj = error.response.data[0];
                if (dataObj.hasOwnProperty('message')) {
                    throw new Error(dataObj.message);
                }
            }

            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    setDefaultCard = async (cardId: string) => {
        let response: any = null;
        let error: any = null;
        response = await BaseService.patch('cards/' + cardId + '/default').catch((err) => (error = err));

        if (error !== null) {
            if (error.response && error.response.data && error.response.data.length > 0) {
                const dataObj = error.response.data[0];
                if (dataObj.hasOwnProperty('message')) {
                    throw new Error(dataObj.message);
                }
            }

            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };

    removeCard = async (cardId: string) => {
        let response: any = null;
        let error: any = null;

        response = await BaseService.delete('cards/' + cardId).catch((err) => (error = err));

        if (error !== null) {
            if (error.response && error.response.data && error.response.data.length > 0) {
                const dataObj = error.response.data[0];
                if (dataObj.hasOwnProperty('message')) {
                    throw new Error(dataObj.message);
                }
            }

            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data.data, response.data.message, null);
    };
}
