import React from 'react';

const Spinner: React.FunctionComponent<{ show?: boolean }> = (props) => {
    return (
        <div id="infin8-spinner" className={props.show ? 'page-loader' : 'page-loader page-loader-disable'}>
            <div className="loader-animation">
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Spinner;
