import React, { useState } from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from 'formik';
import { withTranslation } from 'react-i18next';

const ReactstrapFormikInput = ({ field: { ...fields }, form: { touched, errors }, ...props }) => {
    const { id, t, tReady, ...rest } = props;
    const [showPassword, setShowPassword] = useState(false);
    const isInValid = Boolean(_.get(touched, fields.name) && _.get(errors, fields.name));

    const onShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <Form.Group controlId={id}>
            {props.label && <Form.Label className={'label-color'}>{props.label}</Form.Label>}
            <div
                className={
                    props.type && props.type === 'password' ? `password-block ${!showPassword ? '' : 'show'}` : ''
                }
            >
                <Form.Control
                    {...fields}
                    {...rest}
                    isInvalid={isInValid}
                    className={props.type && props.type === 'password' && isInValid ? 'img-remove' : ''}
                    type={props.type && props.type === 'password' ? (showPassword ? 'text' : 'password') : props.type}
                />
                {props.type && props.type === 'password' && (
                    <span
                        className="password-icon"
                        onClick={onShowPassword}
                        title={!showPassword ? t('show') : t('hide')}
                    ></span>
                )}
            </div>
            <ErrorMessage name={fields.name}>
                {(msg) =>
                    msg !== 'required' ? (
                        <Form.Control.Feedback className="pb-0 error invalid-feedback d-block" type="invalid">
                            {msg}
                        </Form.Control.Feedback>
                    ) : (
                        ''
                    )
                }
            </ErrorMessage>
        </Form.Group>
    );
};
export default withTranslation()(ReactstrapFormikInput);
