import BaseService from '../../apis/base-service';
import StandardResponse from '../../models/standard-response';

export default class SubscriptionService {
    getAllPackages = async () => {
        let response: any = null;
        let error: any = null;
        response = await BaseService.get('subscription-packages').catch((err) => {
            error = err;
        });
        if (error !== null) {
            if (error.response && error.response.data && error.response.data.length > 0) {
                const dataObj = error.response.data[0];
                if (dataObj.hasOwnProperty('message')) {
                    throw new Error(dataObj.message);
                }
            }

            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data, response.message, null);
    };

    getUserSubscription = async () => {
        let response: any = null;
        let error: any = null;
        response = await BaseService.get('subscription').catch((err) => {
            error = err;
        });
        if (error !== null) {
            if (error.response && error.response.data && error.response.data.length > 0) {
                const dataObj = error.response.data[0];
                if (dataObj.hasOwnProperty('message')) {
                    throw new Error(dataObj.message);
                }
            }

            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data, null, null);
    };

    ChangeUserSubscription = async (
        packageId: string,
        cardId: string,
        startFromNow: boolean,
        ignoreNextSubscription: boolean,
    ) => {
        let response: any = null;
        let error: any = null;

        response = await BaseService.post('user/subscription', {
            packageId: packageId,
            cardId: cardId,
            startFromNow: startFromNow,
            ignoreNextSubscription: ignoreNextSubscription,
        }).catch((err) => (error = err));
        if (error !== null) {
            if (error.response && error.response.data && error.response.data.length > 0) {
                const dataObj = error.response.data[0];
                if (dataObj.hasOwnProperty('message')) {
                    throw new Error(dataObj.message);
                }
            }

            throw new Error(error);
        }
        return new StandardResponse<any>(response.status, response.data, response.data.message, null);
    };
}
